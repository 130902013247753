import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from './Misc/DataGrid';
import RoleEditModal from './RoleEditModal';
const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    background-color: white;
    font-weight: bold;
    text-align: center;`;

    const AddRole=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    `;

export class Userc extends Component {


  constructor(props) {
    super(props);
    this.state = {
        FirstName: '',
        MiddleName: '',
        LastName:'',
        UserName:'',
        Email:'',
        Password:'',
        ConfirmPassword:'',
      
      ready:false,
      showtoast1:false,
      showtoast2:false,
      showtoast3:false,
      error:'',
      roleeditid:0,
      showmodal:false,
      errors: {}
      
       
      }
}
toggleShowA=(event)=>{
  this.setState({showtoast1:false});
}
toggleShowB=(event)=>{
  this.setState({showtoast2:false});
  this.setState({error:''});
}
toggleShowC=(event)=>{
  this.setState({showtoast3:false});
}

componentDidMount() { 
  
 this.setState({ready:true});
}

  submitUser=(event)=>{
    event.preventDefault();
    if(this.state.Password!=this.state.ConfirmPassword){
      let errors={};
      errors["PasswordHash"] ='Password doesnot match confirm password!' ;
      this.setState({errors: errors});
      return;
    }
    
    let postData = {
     
      "FirstName": this.state.FirstName ,   
      "MiddleName":this.state.MiddleName,
      "LastName":this.state.LastName,
      "Email":this.state.Email,
      "UserName":this.state.UserName,
      "LastName":this.state.LastName,
      "PasswordHash":this.state.Password

       }
       //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/createuser';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };

  fetch(url, requestOptions)
        .then(res => res.json())
        .then(result => {
          //gbconsole.log(result)          
         var status=result['status'];
          
     if(status=='Success'){
    
  
      let errors = {};
     this.setState({FirstName:'',MiddleName:'',LastName:'',Email:'',UserName:'',Password:'',ConfirmPassword:'',showmodal:false,errors:errors});
     this.setState({showtoast1:true});
     localStorage.setItem("Users", JSON.stringify(result.users));
     localStorage.setItem("UserRolesAssigned", JSON.stringify(result.rolesassigned));
    
          
     }else
     {
    //   //  //gbconsole.log("error=",result['error'])
    let errors = {};
    errors["FirstName"] =result['validationerror']['FirstName'] ;
    errors["LastName"] =result['validationerror']['LastName'] ;
    errors["UserName"] =result['validationerror']['UserName'] ;
    errors["Email"] =result['validationerror']['Email'] ;
    errors["PasswordHash"] =result['validationerror']['PasswordHash'] ;
    //gbconsole.log('errors=',errors);
    


         this.setState({error:'Error',errors: errors});
       
         this.setState({showtoast2:true});
      
     }   

        }).catch(err=>{
          //gbconsole.log(err)
      }); 


  }
  markup (val) {
		return { __html: val }
	}
  hideModal=()=>{
    this.setState({showmodal:false,roleeditid:0});
}
  handleChange = (event) => {    
    this.setState({[event.target.name]: event.target.value});    
  }
  seteditid=(editid)=>{
   
    for(var i=0;i<this.state.userroles.length;i++){
      if(this.state.userroles[i]['ID']==editid){
        this.setState({rolename:this.state.userroles[i]['Name'],roledescription:this.state.userroles[i]['Description']});
        break;
      }
    }
  this.setState({roleeditid:editid},()=>{
    //gbconsole.log('roleeditid=',this.state.roleeditid)
    this.setState({showmodal:true},()=>{
     
    })
  });
  
  }
  
    render() {
      
            if(this.state.ready==false)
            return(<div></div>)
            else
     

        return (
          
              


             <div>
              <FormHeader>Create user</FormHeader>
              <Form onSubmit={this.submitUser}>
                 <Container>
              <Row>
              <Col xs="12" lg="4">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>First Name<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="text"  name="FirstName" value={this.state.FirstName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="4">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Middle Name</Form.Label>
    <Form.Control type="text"  name="MiddleName" value={this.state.MiddleName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["MiddleName"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="4">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Last Name
      {/* <b style={{color: "red"}}>(*)</b> */}
    
    </Form.Label>
    <Form.Control type="text"  name="LastName" value={this.state.LastName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
    
  </Form.Group>
</Col>
</Row>
<Row>
  
<Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>User Name<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="text"  name="UserName" value={this.state.UserName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["UserName"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="email"  name="Email" value={this.state.Email} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
    
  </Form.Group>
  </Col>
  </Row>
  <Row>
   
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Password<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="password"  name="Password" value={this.state.Password} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["PasswordHash"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Confirm Password<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="password"  name="ConfirmPassword" value={this.state.ConfirmPassword} onChange={this.handleChange} />
    
  </Form.Group>
  </Col>
  </Row>
  
<Row>
  <Col>
<Button variant="primary" type="submit">
    Submit
  </Button>
  </Col>
</Row>
  
  
 
  
   </Container>
  
</Form>

<Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>User created/updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-failure">Failure</strong>                    
</Toast.Header>
<Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
</Toast>
</div>
             
           









        )
    }
}

export default Userc
