import React from 'react';
import '../style.css';
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import * as BsIcons from "react-icons/bs";
import styled from 'styled-components';
import '../assets/styles/sb-admin-2.css';
import '../assets/styles/sb-admin-2.min.css';
import undraw_posting_photo from '../assets/img/undraw_posting_photo.svg';
import undraw_profile_1 from '../assets/img/undraw_profile_1.svg';
import undraw_profile_2 from '../assets/img/undraw_profile_2.svg';
import undraw_profile_3 from '../assets/img/undraw_profile_3.svg';
import undraw_profile from '../assets/img/undraw_profile.svg';
import profileicon from '../assets/images/profile_Icon.png';
import logoimage from '../assets/images/yoga.png';
import CanvasJSReact from '../assets/canvasjs.react';



class Home extends React.Component {


    constructor(props) {
        super(props);
    }
    
    componentDidMount() { 

      
    
        

        
       
      }

      


      render() { 
        const WorkArea = styled.div`
        background: white;
        left:250;
        height: 100vh;
        display: flex;
        
      `;

      const options = {
        title: {
          text: "Basic Column Chart in React"
        },
        data: [{				
                  type: "column",
                  dataPoints: [
                      { label: "Apple",  y: 10  },
                      { label: "Orange", y: 15  },
                      { label: "Banana", y: 25  },
                      { label: "Mango",  y: 30  },
                      { label: "Grape",  y: 28  }
                  ]
         }]
     }

     const options1 = {
			exportEnabled: true,
			animationEnabled: true,
			title: {
				text: "Website Traffic Sources"
			},
			data: [{
				type: "pie",
				startAngle: 75,
				toolTipContent: "<b>{label}</b>: {y}%",
				showInLegend: "true",
				legendText: "{label}",
				indexLabelFontSize: 16,
				indexLabel: "{label} - {y}%",
				dataPoints: [
					{ y: 18, label: "Direct" },
					{ y: 49, label: "Organic Search" },
					{ y: 9, label: "Paid Search" },
					{ y: 5, label: "Referral" },
					{ y: 19, label: "Social" }
				]
			}]}
     var CanvasJS = CanvasJSReact.CanvasJS;
     var CanvasJSChart = CanvasJSReact.CanvasJSChart;

        return (
            <div id="content-wrapper" className="d-flex flex-column">
            
            <div id="content">
              
              <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
               
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars" />
                </button>
               
                {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">
                        
                        <FaIcons.FaSearch/>
                      </button>
                    </div>
                  </div>
                </form> */}
                
                <ul className="navbar-nav ml-auto">
                 
                  <li className="nav-item dropdown no-arrow d-sm-none">
                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-search fa-fw" />
                    </a>
                   
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                      <form className="form-inline mr-auto w-100 navbar-search">
                        <div className="input-group">
                          <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="button">
                              <i className="fas fa-search fa-sm" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </li>
                  
                  <li className="nav-item dropdown no-arrow mx-1">
                    {/* <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      
                      <FaIcons.FaRegBell/>
                      
                      <span className="badge badge-danger badge-counter">3+</span>
                    </a> */}
                   
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                      <h6 className="dropdown-header">
                        Alerts Center
                      </h6>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="mr-3">
                          <div className="icon-circle bg-primary">
                            <i className="fas fa-file-alt text-white" />
                          </div>
                        </div>
                        <div>
                          <div className="small text-gray-500">December 12, 2019</div>
                          <span className="font-weight-bold">A new monthly report is ready to download!</span>
                        </div>
                      </a>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="mr-3">
                          <div className="icon-circle bg-success">
                            <i className="fas fa-donate text-white" />
                          </div>
                        </div>
                        <div>
                          <div className="small text-gray-500">December 7, 2019</div>
                          $290.29 has been deposited into your account!
                        </div>
                      </a>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="mr-3">
                          <div className="icon-circle bg-warning">
                            <i className="fas fa-exclamation-triangle text-white" />
                          </div>
                        </div>
                        <div>
                          <div className="small text-gray-500">December 2, 2019</div>
                          Spending Alert: We've noticed unusually high spending for your account.
                        </div>
                      </a>
                      <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                    </div>
                  </li>
                 
                  <li className="nav-item dropdown no-arrow mx-1">
                    {/* <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-envelope fa-fw" />
                      <FaIcons.FaRegEnvelope/>
                   
                      <span className="badge badge-danger badge-counter">7</span>
                    </a> */}
                   
                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                      <h6 className="dropdown-header">
                        Message Center
                      </h6>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="dropdown-list-image mr-3">
                          <img className="rounded-circle" src={undraw_profile_1} alt="..." />
                          <div className="status-indicator bg-success" />
                        </div>
                        <div className="font-weight-bold">
                          <div className="text-truncate">Hi there! I am wondering if you can help me with a
                            problem I've been having.</div>
                          <div className="small text-gray-500">Emily Fowler · 58m</div>
                        </div>
                      </a>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="dropdown-list-image mr-3">
                          <img className="rounded-circle" src={undraw_profile_2} alt="..." />
                          <div className="status-indicator" />
                        </div>
                        <div>
                          <div className="text-truncate">I have the photos that you ordered last month, how
                            would you like them sent to you?</div>
                          <div className="small text-gray-500">Jae Chun · 1d</div>
                        </div>
                      </a>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="dropdown-list-image mr-3">
                          <img className="rounded-circle" src={undraw_profile_3} alt="..." />
                          <div className="status-indicator bg-warning" />
                        </div>
                        <div>
                          <div className="text-truncate">Last month's report looks great, I am very happy with
                            the progress so far, keep up the good work!</div>
                          <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                        </div>
                      </a>
                      <a className="dropdown-item d-flex align-items-center" href="#">
                        <div className="dropdown-list-image mr-3">
                          <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt="..." />
                          <div className="status-indicator bg-success" />
                        </div>
                        <div>
                          <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                            told me that people say this to all dogs, even if they aren't good...</div>
                          <div className="small text-gray-500">Chicken the Dog · 2w</div>
                        </div>
                      </a>
                      <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                    </div>
                  </li>
                  <div className="topbar-divider d-none d-sm-block" />
                 
                  <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">{localStorage.getItem('FirstName')}</span>
                      <img className="img-profile rounded-circle" src={profileicon} />
                    </a>
                  
                     {/* <div classname="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                        Profile
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                        Settings
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                        Activity Log
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                        Logout
                      </a>
                    </div> */}
                  </li>
                </ul>
              </nav>
             
              <div className="container-fluid">
              
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                  
                </div>
               
                <div className="row">
               
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Total Stores</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">169</div>
                          </div>
                          <div className="col-auto">
                            {/* <i className="fas fa-calendar fa-2x text-gray-300" /> */}
                            {/* <FcIcons.FcCalendar  /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                              QDN-OPEN</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                          
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">QDN-ACTION DONE
                            </div>
                            
                          </div>
                          <div className="col-auto">
                            {/* <i className="fas fa-clipboard-list fa-2x text-gray-300" /> */}
                            {/* <BsIcons.BsClipboardData /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              QDN-CLOSED</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            {/* <i className="fas fa-comments fa-2x text-gray-300" /> */}
                            {/* <FaIcons.FaComments /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
              
              
             
              </div>
              
            </div>
          
            <footer className="sticky-footer bg-white">
              <div className="container my-auto" style={{backgroundColor:'#fff'}}>
                <div className="copyright text-center my-auto">
                  <span>Copyright © Your Website 2021</span>
                </div>
              </div>
            </footer>
           
          </div>
        );
      }




}

export default Home;
 