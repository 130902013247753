import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';

const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    // background-color: white;
    font-weight: bold;
    text-align: center;`;
    
class UserActivation extends Component {

    constructor(props) {
        super(props);
        this.state = {
          rolename: '',
          roledescription: '',
          users:[],
          ready:false,
          showtoast1:false,
          showtoast2:false,
          showtoast3:false,
          error:'',
          roleeditid:0,
          showmodal:false,
          columnstoview:["UserName","FirstName",,"MiddleName","LastName","Status"],
          
           
          }
    }

    componentDidMount() { 
  //gbconsole.log(localStorage.getItem("Users"));
        var users=JSON.parse(localStorage.getItem("Users") || "[]");
       this.setState({users:users},()=>{this.setState({ready:true})});
       //gbconsole.log('users=',users);
      }
      
      activationcallback=(editid)=>{
        //gbconsole.log('editid=',editid)

        let postData = {
     
          
          "UserID":editid
    
           }
         //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/updateuserstatus';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };
    
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)          
              var status=result['status'];
              
        if(status=='Failure'){
        
        //gbconsole.log("error=",result['error'])
     //   this.setState({error:result['error']});
       
     //   this.setState({showtoast2:true});
              
        }else
        {
           
            localStorage.setItem("Users", JSON.stringify(result.users));
            var users=JSON.parse(localStorage.getItem("Users") || "[]");
            this.setState({users:users});
            //gbconsole.log('users=',users);
           
            
        }   
    
            }); 



      }

    render() {
        if(this.state.ready==false)
        return(<div></div>)
        else
 

    return (
        <Container>
            <Row>
                <Col>
        <FormHeader>Existing Users</FormHeader>
              
        <DataGrid  rowsarray={this.state.users} showeditbutton={false} activationcallback={this.activationcallback }   columns={this.state.columnstoview} />

        </Col>
  </Row>
  </Container>
    );
    }
}

export default UserActivation;
