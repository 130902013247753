import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal,Image} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';
import FileBase64 from 'react-file-base64'; //https://www.npmjs.com/package/react-file-base64
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import parse from 'html-react-parser';
import { green } from '@material-ui/core/colors';
import waitimage from '../assets/images/wait.webp';

const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    // background-color: white;
    font-weight: bold;
    text-align: center;`;

    const AddRole=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    `;

    

export class DropDownMaster extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ready: false,
            tablename:this.props.tablename,
            tableid:0,  
            tablecaption:'',
            tableadvancefilter:0,         
            fielddetails:[],         
            tablelists:[],
            columnstoview:["Fieldvalue"],
            showtoast1:false,
            showtoast2:false,
            showtoast3:false,
            error:'',
            showmodal:false,
            actualvisiblecolumns:[],
            iswizard:0,
            wizardno:0,
            wizardtitle:'',
            wizardbutton:'',
            wizardnos:[],
            wizardbuttons:[],
            wizardtitles:[],
            admission_sel_courseid:0,
            admission_sel_sessionid:0,
            token:null,
            enqtoken:null,
            selectedidarray:[],
            applicationfollowupdate:'',
            applicationfollowupcomment:'',
            applicationstatus:'',
            studentapplicationbutton:'Apply',
            submitbutton:'Submit',

			
			
            
            
        }
    }
    componentDidUpdate(prevProps,prevState) {
      if(prevProps.tablename!=this.props.tablename){
        this.setState({ready:false},()=>{ this.componentDidMount();})
       
      }
    }

    componentDidMount() { 
      const query = new URLSearchParams(window.location.search);//http://localhost:3000/studentadmission?token=5
      const token = query.get('token')
      this.setState({token:token},()=>{if(token!=null)this.setState({showmodal:true})})
      //gbconsole.log('admission token=',token)//123

      const enqtoken = query.get('enqtoken')
      this.setState({enqtoken:enqtoken},()=>{if(enqtoken!=null)this.setState({showmodal:true})})
      //gbconsole.log('enquiry token=',token)//123

      //gbconsole.log('enquiryid=',this.props.enquiryid);
      //gbconsole.log('props tablename=',this.props.tablename)
      if(token!=undefined||enqtoken!=undefined){

        let postData = {
         
         
          
          "Record":1
           }
         //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/getdatadetails';
         let usertoken =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+usertoken },
          body: JSON.stringify(postData)
        
      };
    
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)    
              try{
         localStorage.setItem("DropDownFields", JSON.stringify(result.dropdownfields));
        localStorage.setItem("DropDownMaster", JSON.stringify(result.dropdownmaster));
        localStorage.setItem("DropDowns", JSON.stringify(result.dropdowns));
        localStorage.setItem("DropDownRows", JSON.stringify(result.dropdownrows));  
              }catch(e){
              console.log(e)
              }
        setTimeout(() => {  
          //gbconsole.log("World!");
          this.initialize(); }, 2000);

  }); 

      }
      else{
        this.initialize();
      }
        
      }

      initialize(){
        //gbconsole.log('initialize')
        const query = new URLSearchParams(window.location.search);//http://localhost:3000/studentadmission?token=5
        const token = query.get('token')
        //gbconsole.log('admission token=',token);

        const enqtoken = query.get('enqtoken')
        //gbconsole.log('enquiry token=',enqtoken);
        let postData = {
         
         
          
          "TableName":this.props.tablename
           }

        let url = process.env.REACT_APP_DEBUG_URL+'/api/filteredrows';
        let usertoken =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+usertoken },
          body: JSON.stringify(postData)
        
      };
        
console.log('tablename=',this.props.tablename);
//this.setState({ready:false})
           fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)    
              try{
                console.log('result',result);
        // localStorage.setItem("DropDownFields", JSON.stringify(result.dropdownfields));
      //  localStorage.setItem("DropDownMaster", JSON.stringify(result.dropdownmaster));
        localStorage.setItem("DropDowns", JSON.stringify(result.dropdowns));
        localStorage.setItem("DropDownRows", JSON.stringify(result.dropdownrows));  


        var dropdownmaster=JSON.parse(localStorage.getItem("DropDownMaster") || "[]");
        var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");
        var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
        var sessioncourseid=0;
        var sessionid=0;
        for(var l=0;l<dropdowns.length;l++){
        if(dropdowns[l]['Session ref']!=undefined){
         //gbconsole.log('--sesref=',dropdowns[l]['Session ref'],'admission token=',token," enquiry token=",enqtoken);
         if(dropdowns[l]['Session ref']==token||dropdowns[l]['Session ref']==enqtoken){
            sessioncourseid=dropdowns[l]['Course'];
            sessionid=dropdowns[l]['ID'];
           //gbconsole.log('course=',sessioncourseid);
           //gbconsole.log('session=',sessionid);
          // this.setState({admission_sel_courseid:sessioncourseid,admission_sel_sessionid:sessionid})
         }
        }

        }
        //gbconsole.log('DropDownMaster=',dropdownmaster)
        //gbconsole.log('DropDownFields=',dropdownfields)
        //gbconsole.log('DropDownRows=',dropdowns)
        var tabledata=[];
        var fieldid=0;
        var fieldarray=[];
        try{
          
          for(var i=0;i<dropdownmaster.length;i++){
            if(this.props.tablename==dropdownmaster[i]['DropdownTableName']){
              this.setState({tableid:dropdownmaster[i]['ID']});
              this.setState({tablecaption:dropdownmaster[i]['FormCaption']});
              this.setState({tableadvancefilter:dropdownmaster[i]['AdvanceFilter']});
              break;
            }
          }

           var columnstoview=[];
           var wizardno=0;
           var wizardtitle='';
           var wizardbutton='';

           var wizardnos=[];
           var wizardtitles=[];
           var wizardbuttons=[];

           //multi level sort wizardno/rownum/fieldorder

           dropdownfields.sort(function (a, b) {
            return a.WizardNo - b.WizardNo || a.RowNum - b.RowNum || a.FieldOrder - b.FieldOrder;
            
        });

            for(var i=0;i<dropdownfields.length;i++){

              if(this.props.tablename==dropdownfields[i]['DropdownTableName']){

                var fieldobj={};
           
            fieldobj['ID']=dropdownfields[i]['ID'];
            fieldobj['DropdownTableID']=dropdownfields[i]['DropdownTableID']
            
            fieldobj['FieldName']=dropdownfields[i]['FieldName'];
            fieldobj['FieldLabel']=dropdownfields[i]['FieldLabel'];
            //gbconsole.log('fieldname=',dropdownfields[i]['FieldName']);
            fieldobj['FieldValue']=((dropdownfields[i]['ID']==252||dropdownfields[i]['ID']==130)&&sessioncourseid!=0)?sessioncourseid:(dropdownfields[i]['ID']==253&&sessionid!=0?sessionid:'');
            fieldobj['FieldValue']=dropdownfields[i]['ID']==297?this.props.enquiryid:fieldobj['FieldValue'];
            fieldobj['FieldValue']=dropdownfields[i]['ID']==298?this.props.enquiryreference:fieldobj['FieldValue'];
            fieldobj['FieldValue']=dropdownfields[i]['ID']==322?this.props.enquiryreference:fieldobj['FieldValue'];
            fieldobj['FieldValue']=dropdownfields[i]['ID']==400?this.props.qdnid:fieldobj['FieldValue'];
            fieldobj['FieldValue']=dropdownfields[i]['ID']==401?this.props.qdnno:fieldobj['FieldValue'];
            fieldobj['FieldValue']=dropdownfields[i]['ID']==388?localStorage.getItem('FirstName'):fieldobj['FieldValue'];
            if(dropdownfields[i]['ID']==389){
              var drop_down_rows=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
              var total=0;
              for(var k=0;k<drop_down_rows.length;k++){
                if(drop_down_rows[k]['QDN NO'].startsWith('TQDN/'+localStorage.getItem('UserID')+'/'))total++;
              }
              const d = new Date();

              fieldobj['FieldValue']='TQDN/'+localStorage.getItem('UserID')+'/'+(d.getMonth()+1)+''+d.getFullYear()+'/N-'+(total==0?1:++total);

            }
            fieldobj['FieldErrorText']='';
            fieldobj['FieldValidation']=dropdownfields[i]['FieldValidation'];


            fieldobj['RecordID']=0;
            fieldobj['SubTable']=dropdownfields[i]['SubTable'];
            fieldobj['FieldType']=dropdownfields[i]['FieldType'];
            fieldobj['RowNum']=dropdownfields[i]['RowNum'];
            fieldobj['IsWizard']=dropdownfields[i]['IsWizard'];
            fieldobj['FieldOrder']=dropdownfields[i]['FieldOrder'];
            fieldobj['IsForeignKey']=dropdownfields[i]['IsForeignKey'];
            fieldobj['PrimaryDropdownField']=dropdownfields[i]['PrimaryDropdownField'];
            fieldobj['PrimaryDropdownField2']=dropdownfields[i]['PrimaryDropdownField2'];
            fieldobj['PrimaryDropdownMaster']=dropdownfields[i]['PrimaryDropdownMaster'];
            fieldobj['Primary_FK_Dropdown_Field_ID']=dropdownfields[i]['Primary_FK_Dropdown_Field_ID'];
            fieldobj['Primary_FK_Dropdown_Field_ID_2']=dropdownfields[i]['Primary_FK_Dropdown_Field_ID_2'];
            fieldobj['Primary_FK_Dropdown_MasterID']=dropdownfields[i]['Primary_FK_Dropdown_MasterID'];

            fieldobj['WizardTitle']=dropdownfields[i]['WizardTitle'];
            fieldobj['WizardNo']=dropdownfields[i]['WizardNo'];
            fieldobj['WizardButton']=dropdownfields[i]['WizardButton'];

            if(wizardno!=dropdownfields[i]['WizardNo']){
              wizardno=dropdownfields[i]['WizardNo'];
              wizardnos.push(wizardno);
              wizardtitles.push(dropdownfields[i]['WizardTitle']);
              wizardbuttons.push(dropdownfields[i]['WizardButton']);


            }

            var dropdownvalues=[];
            if(dropdownfields[i]['FieldType']=="Static Dropdown"){
              var drop_arr=dropdownfields[i]['StaticDropdownText'].split('|');
              for(var stcounter=0;stcounter<drop_arr.length;stcounter++){
                var dropdownvalueobj={};
                dropdownvalueobj['ID']=drop_arr[stcounter];
                dropdownvalueobj['Value']=drop_arr[stcounter];
                dropdownvalues.push(dropdownvalueobj);

              }

            }
          else{

            for(var k=0;k<dropdowns.length;k++){
              var dropdownvalueobj={};

              if(dropdowns[k]['DropdownName']==dropdownfields[i]['PrimaryDropdownMaster']&&dropdowns[k][dropdownfields[i]['PrimaryDropdownField']]!=undefined&&dropdownfields[i]['FieldType']=="Dropdown"){
              //gbconsole.log(dropdowns[k]['ID'],"--",dropdowns[k][dropdownfields[i]['PrimaryDropdownField']])
              dropdownvalueobj['ID']=dropdowns[k]['ID'];
              dropdownvalueobj['Value']=dropdowns[k][dropdownfields[i]['PrimaryDropdownField']];
              dropdownvalueobj['Value2']=dropdowns[k][dropdownfields[i]['PrimaryDropdownField2']];
              dropdownvalues.push(dropdownvalueobj);
           

              
            }else if(dropdowns[k]['DropdownName']==dropdownfields[i]['PrimaryDropdownMaster']&&dropdowns[k][dropdownfields[i]['PrimaryDropdownField']]!=undefined&&dropdownfields[i]['FieldType']=="Multiple dropdown"){
              
           
              dropdownvalueobj['key']=dropdowns[k]['ID'];
              dropdownvalueobj['label']=dropdowns[k][dropdownfields[i]['PrimaryDropdownField']];
              dropdownvalues.push(dropdownvalueobj);
            }

            }

          }
           
            fieldobj['Dropdownvalues']=dropdownvalues;
         
            fieldobj['SubTableFieldDetails']=(dropdownfields[i]['SubTable']==""||dropdownfields[i]['SubTable']==null||dropdownfields[i]['SubTable']==undefined)?[]:this.getSubTableFieldDetails(dropdownfields[i]['SubTable']);
            fieldobj['SubTableData']=(dropdownfields[i]['SubTable']==""||dropdownfields[i]['SubTable']==null||dropdownfields[i]['SubTable']==undefined)?[]:[];
         

            fieldarray.push(fieldobj);
               columnstoview.push(dropdownfields[i]['FieldName']);


           
              }

            }  

              //gbconsole.log('wizardnos=',wizardnos);
              //gbconsole.log('wizardtitles=',wizardtitles);
              //gbconsole.log('wizardbuttons=',wizardbuttons);


            //Multilevel sorting
            if(fieldarray[0]['RowNum']!=0||fieldarray[1]['RowNum']!=0){
              fieldarray.sort(function (a, b) {
                return a.WizardNo - b.WizardNo || a.RowNum - b.RowNum || a.FieldOrder - b.FieldOrder;
                
            });

            }
            //gbconsole.log('---after sorting--------------->',fieldarray);

          //   fieldarray.sort(function (a, b) {
          //     return a.ID - b.WizardNo || a.RowNum - b.RowNum || a.FieldOrder - b.FieldOrder;
              
          // });
            
          
            this.setState({fielddetails:fieldarray,wizardno:wizardnos[0]==undefined?0:wizardnos[0],wizardtitle:wizardtitles[0],wizardbutton:wizardbuttons[0],wizardbuttons:wizardbuttons,wizardnos:wizardnos,wizardtitles:wizardtitles},()=>{
              //gbconsole.log("fielddetails after initialize=",this.state.fielddetails)
            });

            for(var i=0;i<dropdowns.length;i++){
              //gbconsole.log('dropdownname=',dropdowns[i]['DropdownName']);
                if(dropdowns[i]['DropdownName']==this.props.tablename){

                var Dropdownobj={};
                Dropdownobj['ID']=dropdowns[i]['ID'];   
                for(var j=0;j<dropdownfields.length;j++){   
                  if(this.props.tablename==dropdownfields[j]['DropdownTableName']){     
                    //gbconsole.log('fieldnamedropdown=',dropdownfields[j]['FieldName']);
                    //gbconsole.log('fieldnamedropdown=',dropdowns[i][dropdownfields[j]['FieldName']]);
                  if(dropdownfields[j]['IsForeignKey']==1){
                  var optionvalues=dropdowns[i][dropdownfields[j]['FieldName']]==undefined?'':dropdowns[i][dropdownfields[j]['FieldName']];
                  var optionarray=optionvalues.length==0?[]:optionvalues.split(",");
                  var optionstring=[];

                  for(var l=0;l<optionarray.length;l++){
                    for(var n=0;n<dropdowns.length;n++){
                      if(dropdowns[n]['ID']==optionarray[l]){
                        optionstring.push(dropdowns[n][dropdownfields[j]['PrimaryDropdownField']]);
                        //gbconsole.log('value=',dropdowns[n][dropdownfields[j]['PrimaryDropdownField']])

                      }
                    }

                  }

                  Dropdownobj[''+dropdownfields[j]['FieldName']]=dropdowns[i][dropdownfields[j]['FieldName']]==undefined?'':optionstring.join();


                }else{
                  Dropdownobj[''+dropdownfields[j]['FieldName']]=dropdowns[i][dropdownfields[j]['FieldName']]==undefined?'':dropdowns[i][dropdownfields[j]['FieldName']];


                }
                                  
              }
                }
                tabledata.push(Dropdownobj);

                }
                

            }

        }catch(e){
          //gbconsole.log('error123--- ',e)
        }
        //gbconsole.log('...........tabledata=',tabledata);
        var tbldata=tabledata;
        if(this.props.enquiryid!=undefined&&this.props.tablename=='FollowupMaster'){
         var tabledatatemp=[];
         for(var tbl=0;tbl<tbldata.length;tbl++){
         if(tbldata[tbl]['Enquiry ID']!=this.props.enquiryid){
           continue;
         }
         else{
          tabledatatemp.push(tbldata[tbl]);
         }
         }
         tabledata=tabledatatemp;
        }

         //Student Application

         if(this.props.enquiryid!=undefined&&this.props.tablename=='Student Application Details'){
          //gbconsole.log('---Student Application Details--');
          var tabledatatemp=[];
          for(var tbl=0;tbl<tbldata.length;tbl++){
          if(tbldata[tbl]['Application ID']!=this.props.enquiryid){
            continue;
          }
          else{
           tabledatatemp.push(tbldata[tbl]);
          }
          }
          tabledata=tabledatatemp;
         }


         //Qdn details

         if(this.props.qdnid!=undefined&&this.props.tablename=='QDN Details'){
        //   //gbconsole.log('---Student Application Details--');
          var tabledatatemp=[];
          for(var tbl=0;tbl<tbldata.length;tbl++){
          if(tbldata[tbl]['QDNID']!=this.props.qdnid){
            continue;
          }
          else{
           tabledatatemp.push(tbldata[tbl]);
          }
          }
          tabledata=tabledatatemp;
          }

        
        this.setState({tablelists:tabledata,columnstoview:columnstoview,admission_sel_courseid:sessioncourseid,admission_sel_sessionid:sessionid},()=>{
        //gbconsole.log('admissioncourseid=',this.state.admission_sel_courseid,'admissionsessionid=',this.state.admission_sel_sessionid);
        this.setState({ready:true})});
        //gbconsole.log('tabledata=',tabledata);
        //gbconsole.log('columns toview',columnstoview)
              }catch(e){
              console.log(e)
              }
        setTimeout(() => {  
          //gbconsole.log("World!");
        //  this.initialize(); 
        
        }, 2000);

  }); 

        
        
      }

      handleStudentApplication(){
        //gbconsole.log('id array=',this.state.selectedidarray)
        var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");
        var fielddetails=[];
        var tableid='';

        for(var i=0;i<dropdownfields.length;i++){

          if(dropdownfields[i]['DropdownTableName']=='Student Application Details'){

            var fieldobj={};
       
        fieldobj['ID']=dropdownfields[i]['ID'];
        tableid=fieldobj['DropdownTableID']=dropdownfields[i]['DropdownTableID'];
        fieldobj['RecordID']=0;
        fieldobj['FieldValue']=dropdownfields[i]['ID']==323?this.state.applicationstatus:(dropdownfields[i]['ID']==324?this.state.applicationfollowupdate:(dropdownfields[i]['ID']==325?this.state.applicationfollowupcomment:''));
        fielddetails.push(fieldobj);
          }
      }

        let postData = {
         
         
          "TableID":tableid,
          "Record":fielddetails,
          "ApplicationIDArray":this.state.selectedidarray
           }
         //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/handleapplication';
         let usertoken =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+usertoken },
          body: JSON.stringify(postData)
        
      };
    this.setState({studentapplicationbutton:'Loading...'})
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)    
              this.setState({studentapplicationbutton:'Apply'})
    try{
        localStorage.setItem("DropDowns", JSON.stringify(result.dropdowns));
        localStorage.setItem("DropDownRows", JSON.stringify(result.dropdownrows));  
    }catch(e){
      console.log(e)
    }
        let applicationidarray=[];
        this.setState({applicationstatus:'',applicationfollowupcomment:'',applicationfollowupdate:'',selectedidarray:applicationidarray})
        
        setTimeout(() => {  
          //gbconsole.log("World!");
          this.initialize(); 

          this.setState({ready:false},()=>{
            this.setState({ready:true})
          })
        
        
        }, 500);

  }); 



      }

      getdropdownvalues(PrimaryDropdownMaster,PrimaryDropdownField){
    
        var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
       var dropdownvalues=[];
        for(var k=0;k<dropdowns.length;k++){
          var dropdownvalueobj={};

          if(dropdowns[k]['DropdownName']==PrimaryDropdownMaster&&dropdowns[k][PrimaryDropdownField]!=undefined){
          //gbconsole.log(dropdowns[k]['ID'],"--",dropdowns[k][PrimaryDropdownField])
          dropdownvalueobj['ID']=dropdowns[k]['ID'];
          dropdownvalueobj['Value']=dropdowns[k][PrimaryDropdownField];
          dropdownvalues.push(dropdownvalueobj);
       

          
        }
      }
        return dropdownvalues;

      }

      getSubTableFieldDetails(fieldid){

        
        var subtablefieldarray=[];
        var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");
        var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
         for(var i=0;i<dropdownfields.length;i++){

              if(fieldid==dropdownfields[i]['DropdownTableID']){
                var fieldobj={};
           
        fieldobj['ID']=dropdownfields[i]['ID'];
        fieldobj['EditIndex']=-1;
        fieldobj['DropdownTableID']=dropdownfields[i]['ID'];
        fieldobj['FieldName']=dropdownfields[i]['FieldName'];
        fieldobj['FieldLabel']=dropdownfields[i]['FieldLabel'];
        //gbconsole.log('fieldname=',dropdownfields[i]['FieldName']);
        fieldobj['FieldValue']='';
        fieldobj['FieldErrorText']='';
        fieldobj['RecordID']=0;
        fieldobj['FieldType']=dropdownfields[i]['FieldType'];
        fieldobj['RowNum']=dropdownfields[i]['RowNum'];
        fieldobj['IsWizard']=dropdownfields[i]['IsWizard'];
        fieldobj['FieldOrder']=dropdownfields[i]['FieldOrder'];
        fieldobj['IsForeignKey']=dropdownfields[i]['IsForeignKey'];
        fieldobj['PrimaryDropdownField']=dropdownfields[i]['PrimaryDropdownField'];
        fieldobj['PrimaryDropdownMaster']=dropdownfields[i]['PrimaryDropdownMaster'];
        fieldobj['Primary_FK_Dropdown_Field_ID']=dropdownfields[i]['Primary_FK_Dropdown_Field_ID'];
        fieldobj['Primary_FK_Dropdown_MasterID']=dropdownfields[i]['Primary_FK_Dropdown_MasterID'];

        fieldobj['WizardTitle']=dropdownfields[i]['WizardTitle'];
        fieldobj['WizardNo']=dropdownfields[i]['WizardNo'];
        fieldobj['WizardButton']=dropdownfields[i]['WizardButton'];


        var dropdownvalues=[];
            if(dropdownfields[i]['FieldType']=="Static Dropdown"){
              var drop_arr=dropdownfields[i]['StaticDropdownText'].split('|');
              for(var stcounter=0;stcounter<drop_arr.length;stcounter++){
                var dropdownvalueobj={};
                dropdownvalueobj['ID']=drop_arr[stcounter];
                dropdownvalueobj['Value']=drop_arr[stcounter];
                dropdownvalues.push(dropdownvalueobj);

              }

            }
          else{

            for(var k=0;k<dropdowns.length;k++){
              var dropdownvalueobj={};

              if(dropdowns[k]['DropdownName']==dropdownfields[i]['PrimaryDropdownMaster']&&dropdowns[k][dropdownfields[i]['PrimaryDropdownField']]!=undefined&&dropdownfields[i]['FieldType']=="Dropdown"){
              //gbconsole.log(dropdowns[k]['ID'],"--",dropdowns[k][dropdownfields[i]['PrimaryDropdownField']])
              dropdownvalueobj['ID']=dropdowns[k]['ID'];
              dropdownvalueobj['Value']=dropdowns[k][dropdownfields[i]['PrimaryDropdownField']];
              dropdownvalues.push(dropdownvalueobj);
           

              
            }else if(dropdowns[k]['DropdownName']==dropdownfields[i]['PrimaryDropdownMaster']&&dropdowns[k][dropdownfields[i]['PrimaryDropdownField']]!=undefined&&dropdownfields[i]['FieldType']=="Multiple dropdown"){
              
           
              dropdownvalueobj['key']=dropdowns[k]['ID'];
              dropdownvalueobj['label']=dropdowns[k][dropdownfields[i]['PrimaryDropdownField']];
              dropdownvalues.push(dropdownvalueobj);
            }

            }

          }
           


        fieldobj['Dropdownvalues']=dropdownvalues;  
        subtablefieldarray.push(fieldobj); 
              }
            }
        return subtablefieldarray;

         

      }

      getSubTableData(tableid,editid,subfielddetails){

        //gbconsole.log('---------edit id=',editid);
        //gbconsole.log('----subfields---',subfielddetails);
        //gbconsole.log('tableid==',tableid)
        var subtablefieldvaluearray=[];
        var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
        var dropdowns=JSON.parse(localStorage.getItem("DropDowns") || "[]");
        var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");
        //gbconsole.log('dropdowns==>',dropdowns)
        //gbconsole.log('dropdownfields==>',dropdownfields)
        // if(editid==0){
             
        //      for(var j=0;j<dropdownfields.length;j++){
        //       var fieldvalueobj={};
              
        //      if(fieldid==dropdownfields[j]['DropdownTableID']){
        //       //gbconsole.log('----tableid=',dropdownfields[j]['DropdownTableID'],'--',dropdownfields[j]['FieldName']);
        //      fieldvalueobj['RecordID']=editid;
        //      fieldvalueobj['FieldName']=dropdownfields[j]['FieldName'];
        //      fieldvalueobj['FieldValue']='';
        //      subtablefieldvaluearray.push(fieldvalueobj);
        //      }
        //      }

        // }else{
        
        // for(var i=0;i<dropdowns.length;i++){
          
        //     var fieldvaluearray=[];
        //     if(dropdowns[i]['ID']==editid){
        //    for(var j=0;j<dropdownfields.length;j++){
        //      if(dropdownfields[j]['DropdownTableID']==tableid){
        //     var fieldvalueobj={};
        //      fieldvalueobj['RecordID']=editid;
        //      fieldvalueobj['FieldType']=dropdownfields[j]['FieldType'];
        //      fieldvalueobj['FieldName']=dropdownfields[j]['FieldName'];
        //      fieldvalueobj['FieldID']=dropdownfields[j]['ID'];
        //      fieldvalueobj['FieldValue']=dropdowns[i][dropdownfields[j]['FieldName']];
        //      var fieldindex=0;
        //      //gbconsole.log('fielddetails====>>>',this.state.fielddetails);
        //      for(var k=0;k<this.state.fielddetails;k++){
        //       if(this.state.fielddetails[k]['ID']==dropdownfields[j]['ID']){
        //         fieldindex=k;
        //         break;
        //       }
              
        //      }
        //      fieldvalueobj['FieldTextValue']=dropdownfields[j]['FieldType']=='Dropdown'?this.getdropdownvalue(dropdowns[i][dropdownfields[j]['FieldName']],this.getdropdownvalues(dropdownfields[j]['PrimaryDropdownMaster'],dropdownfields[j]['PrimaryDropdownField'])):dropdowns[i][dropdownfields[j]['FieldName']]; //this.getdropdownvalue(dropdowns[i][dropdownfields[j]['FieldName']],this.state.fielddetails[fieldindex]['Dropdownvalues'])
                         
        //      fieldvaluearray.push(fieldvalueobj);
        //      }
        //    }
        //    subtablefieldvaluearray.push(fieldvaluearray);
          
        
        //   }
        // }


        var subfields=[];
        // if(tableid==48){
        //   subfields=[243,244,245,250];
        // }
        // if(tableid==49){
        //   subfields=[246];
        // }
        // if(tableid==50){
        //   subfields=[248,251];
        // }
       
        for(var sc=0;sc<subfielddetails.length;sc++){
         
          subfields.push(subfielddetails[sc]['ID']);
         
        }
          
          
          for(var i=0;i<dropdowns.length;i++){
            if(dropdowns[i]['RowID']==editid){
              var fieldvaluearray=[];
              for(var j=0;j<dropdownfields.length;j++){
            //    //gbconsole.log("i="+i+"j="+j);
                
             
                if(dropdownfields[j]['ID']==dropdowns[i]['FieldID']&&subfields.includes(dropdowns[i]['FieldID'])){
                  
          var fieldvalueobj={};
           fieldvalueobj['RecordID']=editid;
           fieldvalueobj['FieldType']=dropdownfields[j]['FieldType'];
           fieldvalueobj['FieldName']=dropdownfields[j]['FieldName'];
           fieldvalueobj['FieldID']=dropdownfields[j]['ID'];
           fieldvalueobj['FieldValue']=dropdowns[i]['Fieldvalue'];
          //  var fieldindex=0;
          //  //gbconsole.log('fielddetails====>>>',this.state.fielddetails);
          //  for(var k=0;k<this.state.fielddetails;k++){
          //   if(this.state.fielddetails[k]['ID']==dropdownfields[j]['ID']){
          //     fieldindex=k;
          //     break;
          //   }
            
          //  }
           fieldvalueobj['FieldTextValue']=dropdownfields[j]['FieldType']=='Dropdown'?this.getdropdownvalue(dropdowns[i]['Fieldvalue'],this.getdropdownvalues(dropdownfields[j]['PrimaryDropdownMaster'],dropdownfields[j]['PrimaryDropdownField'])):dropdowns[i]['Fieldvalue']; //this.getdropdownvalue(dropdowns[i][dropdownfields[j]['FieldName']],this.state.fielddetails[fieldindex]['Dropdownvalues'])
                       
           fieldvaluearray.push(fieldvalueobj);
          // if(subfields.includes(dropdowns[i+1]['FieldID'])&&dropdownfields[j+1]['ID']==dropdowns[i+1]['FieldID'])
         //  i++;
           
           }
         }
         if(fieldvaluearray.length>0)
         subtablefieldvaluearray.push(fieldvaluearray);
        
      
        }
      }


     //gbconsole.log('stfarray=',subtablefieldvaluearray)

    //  }
    var farr=[];
    var subarray=[];
    var maxid=0;
    for(var k=0;k<subtablefieldvaluearray.length;k++){
      var id=subtablefieldvaluearray[k][0]['FieldID'];
      if(id>maxid)maxid=id;
    }
    for(var k=0;k<subtablefieldvaluearray.length;k++){
     var id=subtablefieldvaluearray[k][0]['FieldID'];
      //gbconsole.log('ttt=',id);
     
      var fieldvalueobj={};
      fieldvalueobj['RecordID']=subtablefieldvaluearray[k][0]['RecordID'];
      fieldvalueobj['FieldType']=subtablefieldvaluearray[k][0]['FieldType'];
      fieldvalueobj['FieldName']=subtablefieldvaluearray[k][0]['FieldName'];
      fieldvalueobj['FieldID']=subtablefieldvaluearray[k][0]['FieldID'];
      fieldvalueobj['FieldValue']=subtablefieldvaluearray[k][0]['FieldValue'];
     //  var fieldindex=0;
     //  //gbconsole.log('fielddetails====>>>',this.state.fielddetails);
     //  for(var k=0;k<this.state.fielddetails;k++){
     //   if(this.state.fielddetails[k]['ID']==dropdownfields[j]['ID']){
     //     fieldindex=k;
     //     break;
     //   }
       
     //  }
      fieldvalueobj['FieldTextValue']=subtablefieldvaluearray[k][0]['FieldTextValue'];
   

      farr.push(fieldvalueobj);
     //if(id==246||id==250||id==251){
       // if(id==246||id==245||id==248){
         if(id==maxid){
     subarray.push(farr);
     farr=[];
      }

    }
    
      //gbconsole.log('---------subtabledate====',subarray);
        return subarray;
        
  
      

      }




      toggleShowA=(event)=>{
        this.setState({showtoast1:false});
      }
      toggleShowB=(event)=>{
        this.setState({showtoast2:false});
        this.setState({error:''});
      }
      toggleShowC=(event)=>{
        this.setState({showtoast3:false});
      }
      handleGencode=(event)=>{
        var optionvalues=[
       'option1textvalue','option1yearvalue','option1monthvalue','option1dayvalue','option1current',
       'option2textvalue','option2yearvalue','option2monthvalue','option2dayvalue','option2current',
       'option3textvalue','option3yearvalue','option3monthvalue','option3dayvalue','option3current',
       'option4textvalue','option4yearvalue','option4monthvalue','option4dayvalue','option4current',
       'option5textvalue','option5yearvalue','option5monthvalue','option5dayvalue','option5current',
       'option6textvalue','option6yearvalue','option6monthvalue','option6dayvalue','option6current',
      ];
      //gbconsole.log(this.state);
      
      var fieldarray=[... this.state.fielddetails];
      var codetext='';
      for(var i=0;i<fieldarray.length;i++){
        if(optionvalues.includes(fieldarray[i]['FieldName'])&&fieldarray[i]['FieldValue']!=''&&fieldarray[i]['FieldValue']!=undefined&&fieldarray[i]['FieldValue']!='null'&&fieldarray[i]['FieldValue']!=null){
          codetext+=fieldarray[i]['FieldName'].includes('current')?parseInt(fieldarray[(i+1)]['FieldValue'])+parseInt(fieldarray[(i)]['FieldValue']):fieldarray[i]['FieldValue'];
        }

      }
        alert(codetext);

      }

      handlevalidation(){
        var returnvalue=true;
        var fielddetails=[...this.state.fielddetails];
        //gbconsole.log('handle validation--fielddetails=',fielddetails);
        for(var i=0;i<fielddetails.length;i++){
          fielddetails[i]['FieldErrorText']='';
          var fieldvalue=fielddetails[i]['FieldValue'];
          var fieldvalidation=fielddetails[i]['FieldValidation'];
          var fieldvalidationarray=fieldvalidation.split(":");
          for(var j=0;j<fieldvalidationarray.length;j++){
            var validationdetail=fieldvalidationarray[j].split("#");
            var validationrule=validationdetail[0];
            var validationerrortext=validationdetail[1];
            if(validationrule=="R"){
              if(fieldvalue==''){
                fielddetails[i]['FieldErrorText']=validationerrortext=='-'?'This Field is required':validationerrortext;
                returnvalue=false;
              }
            }

          }


        }
        this.setState({'fielddetails':fielddetails},()=>{
          //gbconsole.log(this.state.fielddetails)
        });
       
        return returnvalue;
      }
      submitData=(event)=>{
        event.preventDefault();
        if(this.handlevalidation()===false){
          return;
        }
        this.setState({submitbutton:'Loading ...'},()=>{


          let postData = {
         
         
            "TableID":this.state.tableid,
            "Record":this.state.fielddetails
             }
           console.log('postdata=',postData)
           var updatemethod=this.state.token==null&&this.state.enqtoken==null?'updatedropdownrowvalues':'updatedropdownrowvaluesunauth';
           
           let url = process.env.REACT_APP_DEBUG_URL+'/api/'+updatemethod;
           let usertoken =localStorage.getItem("UserToken");
           var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+usertoken },
            body: JSON.stringify(postData)
          
        };
        if(this.state.token!=null||this.state.enqtoken!=null){
          requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData)
          
        };
        }
      
        fetch(url, requestOptions)
              .then(res => res.json())
              .then(result => {
                console.log(result)          
                var status=result['status'];
  
                this.setState({submitbutton:'Submit'})
                
          if(status=='Failure'){
          
          //gbconsole.log("error=",result['error'])
          this.setState({error:result['error']});
         
          this.setState({showtoast2:true});
                
          }else
          { 
            if(this.state.token!=null&&this.props.tablename=='AdmissionTYI'){
              this.setState({showmodal:false})
              //gbconsole.log('---refreshcallback----',result);
              this.props.refreshInvoiceCallback('invoice',result.rowid);
            } 
            else if(this.state.enqtoken!=null&&this.props.tablename=='Enquiry Master'){
              this.setState({showmodal:false})
              //gbconsole.log('---refreshcallback----',result);
              var rowid=result.rowid;
              var dropdowns=result.dropdownrows;
              var enqref='';
              for(var dr=0;dr<dropdowns.length;dr++){
                //gbconsole.log('dr----',dropdowns[dr]['ID'],dropdowns[dr]['Enq ref']);
                if(dropdowns[dr]['ID']==rowid){
                  enqref=dropdowns[dr]['Enq ref'];
                  break;
                }
              }
              //gbconsole.log('enqreference===',enqref);
              this.props.refreshEnquiry('enqcallback',result.rowid,enqref);
            } 
            else{
              try{
              localStorage.setItem("DropDowns", JSON.stringify(result.dropdowns));
              localStorage.setItem("DropDownRows", JSON.stringify(result.dropdownrows));
              }catch(e){
               console.log(e)
              }
            var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
            var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");
            var tabledata=[];
          try{
  
  
            for(var i=0;i<dropdowns.length;i++){
              //gbconsole.log('dropdownname=',dropdowns[i]['DropdownName']);
                if(dropdowns[i]['DropdownName']==this.props.tablename){
  
                var Dropdownobj={};
                Dropdownobj['ID']=dropdowns[i]['ID'];   
                for(var j=0;j<dropdownfields.length;j++){   
                  if(this.props.tablename==dropdownfields[j]['DropdownTableName']){     
                    //gbconsole.log('fieldnamedropdown=',dropdownfields[j]['FieldName']);
                    //gbconsole.log('fieldnamedropdown=',dropdowns[i][dropdownfields[j]['FieldName']]);
  
  
                    if(dropdownfields[j]['IsForeignKey']==1){
                      var optionvalues=dropdowns[i][dropdownfields[j]['FieldName']]==undefined?'':dropdowns[i][dropdownfields[j]['FieldName']];
                      var optionarray=optionvalues.length==0?[]:optionvalues.split(",");
                      var optionstring=[];
    
                      for(var l=0;l<optionarray.length;l++){
                        for(var n=0;n<dropdowns.length;n++){
                          if(dropdowns[n]['ID']==optionarray[l]){
                            optionstring.push(dropdowns[n][dropdownfields[j]['PrimaryDropdownField']]);
                            //gbconsole.log('value=',dropdowns[n][dropdownfields[j]['PrimaryDropdownField']])
    
                          }
                        }
    
                      }
    
                      Dropdownobj[''+dropdownfields[j]['FieldName']]=dropdowns[i][dropdownfields[j]['FieldName']]==undefined?'':optionstring.join();
    
    
                    }else
                    {
                     Dropdownobj[''+dropdownfields[j]['FieldName']]=dropdowns[i][dropdownfields[j]['FieldName']]==undefined?'':dropdowns[i][dropdownfields[j]['FieldName']];
                    }
                
                    }
                }
                tabledata.push(Dropdownobj);
  
                }
                
  
            }
  
        }catch(e){}
  
        var tbldata=tabledata;
          if(this.props.enquiryid!=undefined&&this.props.tablename=='FollowupMaster'){
           var tabledatatemp=[];
           for(var tbl=0;tbl<tbldata.length;tbl++){
           if(tbldata[tbl]['Enquiry ID']!=this.props.enquiryid){
             continue;
           }
           else{
            tabledatatemp.push(tbldata[tbl]);
           }
           }
           tabledata=tabledatatemp;
          }
          //Student Application
  
          if(this.props.enquiryid!=undefined&&this.props.tablename=='Student Application Details'){
            //gbconsole.log('---Student Application Details--');
            var tabledatatemp=[];
            for(var tbl=0;tbl<tbldata.length;tbl++){
            if(tbldata[tbl]['Application ID']!=this.props.enquiryid){
              continue;
            }
            else{
             tabledatatemp.push(tbldata[tbl]);
            }
            }
            tabledata=tabledatatemp;
           }

           console.log('qdnid=',this.props.qdnid)
           console.log('tablename=',this.props.tablename)
           if(this.props.qdnid!=undefined&&this.props.tablename=='QDN Details'){
            var tabledatatemp=[];
            for(var tbl=0;tbl<tbldata.length;tbl++){
            if(tbldata[tbl]['QDNID']!=this.props.enquiryid){
              continue;
            }
            else{
             tabledatatemp.push(tbldata[tbl]);
            }
            }
            tabledata=tabledatatemp;
           }
  
  
        this.setState({ready:false},()=>{
  
          this.setState({tablelists:tabledata},()=>{this.setState({ready:true})});
          //gbconsole.log('tabledata=',tabledata);
          this.setState({fieldvalue:'',showmodal:false,actualvisiblecolumns:[]});
          this.setState({showtoast1:true});
  
        })
      }
              
          }   
      
              }); 



        })
        
    
    
      }
      markup (val) {
            return { __html: val }
        }
        showwizard=(index)=>{
          //gbconsole.log('index=',index)
        }
        getdropdownvalue(id,dropdown){
          //gbconsole.log('id==',id);
          //gbconsole.log('dropdown==',dropdown);
          var returnvalue='';
          if(dropdown!=undefined)
          for(var i=0;i<dropdown.length;i++){
            if(dropdown[i]['ID']==id){
              returnvalue=dropdown[i]['Value'];
              break;

            }
          }
          return returnvalue;

        }
     addSubTableData=(index)=>{
          //gbconsole.log(index)
          var tablefieldvaluearray=[];
          for(var j=0;j<this.state.fielddetails[index].SubTableFieldDetails.length;j++){
            var fieldvalueobj={};
             fieldvalueobj['RecordID']=this.state.fielddetails[index]['RecordID'];
             fieldvalueobj['FieldType']=this.state.fielddetails[index].SubTableFieldDetails[j]['FieldType'];
             fieldvalueobj['FieldID']=this.state.fielddetails[index].SubTableFieldDetails[j]['ID'];
             fieldvalueobj['FieldName']=this.state.fielddetails[index].SubTableFieldDetails[j]['FieldName'];
             fieldvalueobj['FieldValue']=this.state.fielddetails[index].SubTableFieldDetails[j]['FieldValue'];             
             fieldvalueobj['FieldTextValue']=this.state.fielddetails[index].SubTableFieldDetails[j]['FieldType']=='Dropdown'?this.getdropdownvalue(this.state.fielddetails[index].SubTableFieldDetails[j]['FieldValue'],this.state.fielddetails[index].SubTableFieldDetails[j]['Dropdownvalues']):this.state.fielddetails[index].SubTableFieldDetails[j]['FieldValue'];
             tablefieldvaluearray.push(fieldvalueobj);
           }
           //gbconsole.log('newadd=',tablefieldvaluearray);
           var subtabledataarray=[...this.state.fielddetails[index].SubTableData];
          //  var exist=0;
          //  for(var i=0;i<subtabledataarray.length;i++){
          //    for(var j=0;j<subtabledataarray[i].length;j++){
          //      var dropvalue=subtabledataarray[i][j]['FieldName']=='FeesHead'?this.getdropdownvalue(this.state.fielddetails[index].SubTableFieldDetails[j]['FieldValue'],this.state.fielddetails[index].SubTableFieldDetails[j]['dropvalues']):'';
          //    if(subtabledataarray[i][j]['FieldName']=='FeesHead'&&subtabledataarray[i][j]['FieldValue']==dropvalue){
          //     subtabledataarray[i][j+1]['FieldValue']=this.state.fielddetails[index].SubTableFieldDetails[j+1]['FieldValue'];
          //     exist=1;
          //    }
            
          //   }

          //  }
          //  //gbconsole.log('exist===',exist);
          //  if(exist==0)
          //gbconsole.log('oldarray=',subtabledataarray);
          if(this.state.fielddetails[index].SubTableFieldDetails[0]['EditIndex']==-1){
           subtabledataarray.push(tablefieldvaluearray);
          }else{
            subtabledataarray[this.state.fielddetails[index].SubTableFieldDetails[0]['EditIndex']]=tablefieldvaluearray;
          }
           //gbconsole.log('newarray=',subtabledataarray);

           var fielddetails=[...this.state.fielddetails];
          fielddetails[index].SubTableData=subtabledataarray;

          for(var j=0;j<fielddetails[index].SubTableFieldDetails.length;j++){
            
            
           
            fielddetails[index].SubTableFieldDetails[j]['FieldValue']='';
            fielddetails[index].SubTableFieldDetails[j]['EditIndex']=-1;
             
           }


          this.setState({fielddetails:fielddetails});

     }

      hideModal=()=>{
        this.setState({showmodal:false,recordid:0},()=>{

          var fieldarray=[... this.state.fielddetails];
          for(var j=0;j<fieldarray.length;j++){
            fieldarray[j]['FieldValue']=fieldarray[j]['ID']==388?localStorage.getItem('FirstName'):'';
            if(fieldarray[j]['ID']==389){
              var drop_down_rows=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
              var total=0;
              for(var k=0;k<drop_down_rows.length;k++){
                if(drop_down_rows[k]['QDN NO'].startsWith('TQDN/'+localStorage.getItem('UserID')+'/'))total++;
              }
              const d = new Date();

              fieldarray[j]['FieldValue']='TQDN/'+localStorage.getItem('UserID')+'/'+(d.getMonth()+1)+''+d.getFullYear()+'/N-'+(total==0?1:++total);

            }
            fieldarray[j]['FieldErrorText']='';
            fieldarray[j]['RecordID']=0;

          }
          this.setState({fielddetails:fieldarray,actualvisiblecolumns:[]});

        });
    }
    handleSubTableChange=(event)=>{
      var fielddetails=[... this.state.fielddetails];
      this.setState({[event.target.name]: event.target.value});
      for(var i=0;i<fielddetails.length;i++) {
        if(fielddetails[i].FieldType=="SubTable"){
          for(var j=0;j<fielddetails[i].SubTableFieldDetails.length;j++){
           if(fielddetails[i].SubTableFieldDetails[j].FieldName==event.target.name){
            fielddetails[i].SubTableFieldDetails[j].FieldValue=event.target.value;

           }
          }
        }
      }
      this.setState({fielddetails: fielddetails},()=>{ 
        //gbconsole.log(this.state.fielddetails)
      });

    }
      handleChange = (event) => {    
        var optiondrop=['option1','option2','option3','option4','option5','option6']
        //gbconsole.log(event)
        //gbconsole.log(event.target.checked)
        this.setState({[event.target.name]: event.target.id=='formBasicCheckbox'?event.target.checked:event.target.value});   
      var fielddetails=[... this.state.fielddetails];
      
        for(var i=0;i<fielddetails.length;i++) {
          if(fielddetails[i]['FieldName']==event.target.name){
            fielddetails[i]['FieldValue']=event.target.value;
            break;
          }
         
           
        }
        //gbconsole.log('fieldetails=',fielddetails);
        this.setState({fielddetails: fielddetails},()=>{ 
          //gbconsole.log(this.state.fielddetails)
        });

        if(optiondrop.includes(event.target.name)){
         this.handleOptions(event.target.name);

          
        }

      }


      //Enquiry Followups
      followupcallback=(editid)=>{
        //gbconsole.log('enquiryid=',editid)
        console.log('table name=',this.props.tablename);
        console.log('editid=',editid);
        var reffieldid='';
        var followuptable='';
        if(this.props.tablename=='Enquiry Master'){
          reffieldid=141;
          followuptable='followup';
        }
        if(this.props.tablename=='Student Application'){
          reffieldid=301;
          followuptable='studentapplicationfollowup';
        }

        if(this.props.tablename=='Student Application Details'){
          reffieldid=322;
          followuptable='studentapplicationmarks';
        }
        if(this.props.tablename=='Discrepancy'){
           reffieldid=389;
           followuptable='qdndetails';
        }
        //gbconsole.log('followuptable=',followuptable);
        
        var enqref='';
        var dropdowns=JSON.parse(localStorage.getItem("DropDowns") || "[]");
        for(var i=0;i<dropdowns.length;i++){
          //if(dropdowns[i]['RowID']==editid&&dropdowns[i]['FieldID']==141){
            if(dropdowns[i]['RowID']==editid&&dropdowns[i]['FieldID']==reffieldid){
            enqref=dropdowns[i]['Fieldvalue'];
            break;
          }
        }

       // this.props.refreshCallback('followup',editid,enqref);
        this.props.refreshCallback(followuptable,editid,enqref);
      }
      seteditid=(editid)=>{
        //gbconsole.log('seteditid===',editid)
     
        var dropdownfields=JSON.parse(localStorage.getItem("DropDownFields") || "[]");
        var dropdowns=JSON.parse(localStorage.getItem("DropDowns") || "[]");
        var tabledata=[];
        var fieldid=0;
        var fieldarray=[... this.state.fielddetails];
        try{
          for(var j=0;j<fieldarray.length;j++){
            fieldarray[j]['RecordID']=editid;
            fieldarray[j]['FieldValue']='';
            for(var i=0;i<dropdowns.length;i++){
              if(this.props.tablename==dropdowns[i]['DropdownName']&&editid==dropdowns[i]['RowID']&&fieldarray[j]['ID']==dropdowns[i]['FieldID']){
                var fieldobj={};
          
          fieldarray[j]['FieldValue']=dropdowns[i]['Fieldvalue'];

          if(fieldarray[j]['FieldType']=="Multiple dropdown" && fieldarray[j]['FieldValue']!=undefined){
            var fieldvaluearray=fieldarray[j]['FieldValue'].split(",");
            var actualcolumns=[];
            for(var t=0;t<fieldvaluearray.length;t++){
              actualcolumns.push(fieldvaluearray[t]);
            }
            this.setState({actualvisiblecolumns:actualcolumns});
          }
          if(fieldarray[j]['FieldType']=="SubTable" ){
            fieldarray[j]['SubTable']=fieldarray[j]['SubTable'];
            fieldarray[j]['SubTableFieldDetails']=this.getSubTableFieldDetails(fieldarray[j]['SubTable']);
            fieldarray[j]['SubTableData']=this.getSubTableData(fieldarray[j]['SubTable'],editid,fieldarray[j]['SubTableFieldDetails']);
            
          
          }

          
          
              }

            }  

          }
          //gbconsole.log('fielddetails*******=',fieldarray);
            
           
            this.setState({fielddetails:fieldarray},()=>{this.setState({showmodal:true})});


      }catch(e){}
    }

    checkoptionvalue(){

    }

    handleOptions(optionname){
      
      var optionindex=optionname.charAt(6);
      var fieldarray=[... this.state.fielddetails];
      const d = new Date();
      try{
        for(var j=0;j<fieldarray.length;j++){
          
          switch(fieldarray[j]['FieldName']){
            case 'option'+optionindex+'textvalue':
            case 'option'+optionindex+'yearvalue':
            case 'option'+optionindex+'monthvalue':
            case 'option'+optionindex+'dayvalue':
            case 'option'+optionindex+'countersize':
            case 'option'+optionindex+'current':
            case 'option'+optionindex+'increment':
            fieldarray[j]['FieldValue']='';
            
           


          }
          
        }
        var optionvalue='';
        for(var j=0;j<fieldarray.length;j++){
          if(optionname==fieldarray[j]['FieldName']){
            optionvalue=fieldarray[j]['FieldValue'];
          }
        }

        for(var j=0;j<fieldarray.length;j++){
          
        
        switch(fieldarray[j]['FieldName']){
          case 'option'+optionindex+'yearvalue':
            if(optionvalue=='Year')
            fieldarray[j]['FieldValue']=d.getFullYear();
          break;
          case 'option'+optionindex+'monthvalue':
            if(optionvalue=='Month')
            fieldarray[j]['FieldValue']=parseInt(''+d.getMonth())+1;
          break;
          case 'option'+optionindex+'dayvalue':
            if(optionvalue=='Day')
            fieldarray[j]['FieldValue']=d.getDate();
          break;
          case 'option'+optionindex+'countersize':
            if(optionvalue=='Counter')
            fieldarray[j]['FieldValue']=optionindex;
          break;


        }
      }
      
      }catch(e){}
      this.setState({fielddetails: fieldarray},()=>{ 
        //gbconsole.log(this.state.fielddetails)
      });

    }

     isdisplayable(fieldname){
       var returnvalue=true;
       var optionvalues=['option1textvalue','option1yearvalue','option1monthvalue','option1dayvalue','option1countersize','option1current','option1increment',
       'option2textvalue','option2yearvalue','option2monthvalue','option2dayvalue','option2countersize','option2current','option2increment',
       'option3textvalue','option3yearvalue','option3monthvalue','option3dayvalue','option3countersize','option3current','option3increment',
       'option4textvalue','option4yearvalue','option4monthvalue','option4dayvalue','option4countersize','option4current','option4increment',
       'option5textvalue','option5yearvalue','option5monthvalue','option5dayvalue','option5countersize','option5current','option5increment',
       'option6textvalue','option6yearvalue','option6monthvalue','option6dayvalue','option6countersize','option6current','option6increment'
      ];
      var optiondrop=['option1','option2','option3','option4','option5','option6']
       if(optionvalues.includes(fieldname)){
        returnvalue=false;
        var displayfield='';
        var optionindex=fieldname.charAt(6);;
        for(var i=0;i<this.state.fielddetails.length;i++){
          if(this.state.fielddetails[i]['FieldName']=='option'+optionindex){
            displayfield=this.state.fielddetails[i]['FieldValue'];
          }

        }
        switch(fieldname){
          case 'option'+optionindex+'textvalue':
            if(displayfield=='Text')
            returnvalue=true;
            break;
          case 'option'+optionindex+'yearvalue':
            if(displayfield=='Year')
            returnvalue=true;
            break;
          case 'option'+optionindex+'monthvalue':
            if(displayfield=='Month')
           returnvalue=true;
           break;
         case 'option'+optionindex+'dayvalue':
          if(displayfield=='Day')
           returnvalue=true;
            break;
        case 'option'+optionindex+'countersize':
        case 'option'+optionindex+'current':
        case 'option'+optionindex+'increment':  
          if(displayfield=='Counter')
          returnvalue=true;
          break;
        


        }

       }
       return returnvalue;
     }

     getdropdown(tablename,PrimaryDropdownField){

      var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
      //gbconsole.log('dropdown rows---->',dropdowns);
      var dropdownvalues=[];
       for(var k=0;k<dropdowns.length;k++){
         var dropdownvalueobj={};

         if(dropdowns[k]['DropdownName']==tablename){
        // //gbconsole.log(dropdowns[k]['ID'],"--",dropdowns[k][PrimaryDropdownField])
         dropdownvalueobj['ID']=dropdowns[k]['ID'];
         dropdownvalueobj['Value']=dropdowns[k][PrimaryDropdownField];
         dropdownvalues.push(dropdownvalueobj);
      

         
       }
     }

//gbconsole.log('dropdownvalues=--->',dropdownvalues,'length=',dropdownvalues);
    let drop= ( 
    <Form.Control as="select"  onChange={this.handleChange} name='applicationstatus' value={this.state.applicationstatus}>
     <option key ={0} value={0}>Select Action </option>
   
    {
   (()=>{
    //gbconsole.log('length..=',dropdownvalues.length);
     if(dropdownvalues.length>0){
     //gbconsole.log('length=',dropdownvalues.length);
       let dropvalues=[];
         
       for(var p=0;p<dropdownvalues.length;p++){
         

         dropvalues.push(<option  key={dropdownvalues[p]['ID']} value={dropdownvalues[p]['ID']} >{dropdownvalues[p]['Value']} </option>);
                               
         
         

     }
    //gbconsole.log('dropvalues=',dropvalues);
     return dropvalues;
    }


   })()
     
  }
  </Form.Control> 
  
  );
  //gbconsole.log('drop=',drop)

  return drop;

     }
     
      getSubFields(index){

        const disablePastDate = () => {
          const today = new Date();
          const dd = String(today.getDate() + 1).padStart(2, "0");
          const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          const yyyy = today.getFullYear();
          return yyyy + "-" + mm + "-" + dd;
      };

    let formbody=this.state.fielddetails[index].SubTableFieldDetails.map((item,i)=>{
      //---

      if(this.state.fielddetails[index].SubTableFieldDetails[i].IsForeignKey==1 ){
  
  
        if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType=="Dropdown"){

          var sr= (
            
         <td>
  <Form.Control as="select"  disabled={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']=='Course'&&(this.state.admission_sel_courseid!=undefined&&this.state.admission_sel_courseid!=0)?true:this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']=='Session'&&(this.state.admission_sel_sessionid!=undefined&&this.state.admission_sel_sessionid!=0)?true:false} id="selectedrole" className="margin-5 w-60" value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']=='Course'&&this.state.admission_sel_courseid!=undefined&&this.state.admission_sel_courseid!=0?this.state.admission_sel_courseid:this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']=='Session'&&this.state.admission_sel_sessionid!=undefined&&this.state.admission_sel_sessionid!=0?this.state.admission_sel_sessionid:this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleSubTableChange} name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']}>
                  <option key ={0} value={0}>Select {this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']}</option>
                
                 {
                (()=>{
                  if(this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'].length>0){
                  
                    let dropvalues=[];
                      
                    for(var p=0;p<this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'].length;p++){
                       var sel=true;
                       sel=(this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID']==25||this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID']==72)?true:sel;
                       if(this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID'])
                     

                      dropvalues.push(<option  key={this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID']} selected={true}>{this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['Value']} </option>);
                                            
                      
                      
  
                  }
                 
                  return dropvalues;
  
                  }
                  else
                  return null;
                    
                      
                 })()
               }
               </Form.Control>   
  
  
  
 </td>
 
          );
          return  sr ;

        }

        

      }
      
      else{

        if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType=="Static Dropdown"){

          var sr= (
            
         <td>
  
  <Form.Control as="select"    id="selectedrole" className="margin-5" value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleChange} name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']}>
                  <option key ={0} value={0}>Select </option>
                
                 {
                (()=>{
                  if(this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'].length>0){
                  
                    let dropvalues=[];
                      
                    for(var p=0;p<this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'].length;p++){
                        if(this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID'])
                     

                      dropvalues.push(<option  key={this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['ID']} >{this.state.fielddetails[index].SubTableFieldDetails[i]['Dropdownvalues'][p]['Value']} </option>);
                                            
                      
                      
  
                  }
                 
                  return dropvalues;
  
                  }
                  else
                  return null;
                    
                      
                 })()
               }
               </Form.Control>   
  
  
  
 </td>
 
          );
          return  sr ;

        }

       else if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType=="Date"){
          //gbconsole.log('type===date');

          var sr= (
            <td>
           
          <Form.Control type="date"  min={this.state.fielddetails[i]['ID']==122?disablePastDate():null} name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleSubTableChange} />   
            </td>
          );
          return sr;
        }
        
        else if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType=="DateTime"){
          //gbconsole.log('type===date');

          var sr= (
            <td>
           
          <Form.Control type="datetime-local"  name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleSubTableChange} />   
            </td>
          );
          return sr;
        }
        else if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType=="Email"){

          var sr= (
           <td>
         <Form.Control type="email"  name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleChange} />     
            </td>
          );
          return sr;
        }
        else if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType=="Text Area"){

          var sr= (
            <td>
         <Form.Control as="textarea"   name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleChange} />
            </td>
          );
          return sr;
        }
        else{
          if(this.state.fielddetails[index].SubTableFieldDetails[i].FieldType!="Datagrid"&&this.state.fielddetails[index].SubTableFieldDetails[i].FieldType!="ParentTable"){
                      var sr= (
                    
                    <td style={ (this.isdisplayable(this.state.fielddetails[index].SubTableFieldDetails[i].FieldName))?null:{display:'none'}}>
                        <Form.Group controlId="formBasicEmail">
              
                <Form.Label>{this.state.fielddetails[index].SubTableFieldDetails[i].FieldLabel==''?this.state.fielddetails[index].SubTableFieldDetails[i].FieldName:this.state.fielddetails[index].SubTableFieldDetails[i].FieldLabel}</Form.Label>
              <Form.Control type="text"  name={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldName']} value={this.state.fielddetails[index].SubTableFieldDetails[i]['FieldValue']} onChange={this.handleSubTableChange} />
              
              
              </Form.Group>
              </td>
              
                      );
                      return sr;
          }
       
      }
    }



      //--

    })
    return formbody;
      }
      isdropdowndisplayable(index){
        //gbconsole.log('displayable function state fielddetails=',this.state.fielddetails)
        //gbconsole.log('courseid=====',this.state.fielddetails[1].FieldValue)
        
        var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
        var courseid=this.state.fielddetails[1].FieldValue;
        var returnvalue=true;
       if(this.props.tablename=='AdmissionTYI'&&this.state.fielddetails[index]['ID']==292){
        returnvalue=courseid==undefined||courseid==0||courseid==''?false:true;
       // returnvalue=false;
       for(var k=0;k<dropdowns.length;k++){
         if(dropdowns[k]['DropdownID']==27&&dropdowns[k]['ID']==courseid&&(dropdowns[k]['Available Hostel Facility']==undefined||dropdowns[k]['Available Hostel Facility']==''))//Course=27
         {
          returnvalue=false;
          break;
         }
        
       }
       
        
       }
       return returnvalue;
      }

      getfields(rownum){

        const disablePastDate = () => {
          const today = new Date();
          const dd = String(today.getDate() + 1).padStart(2, "0");
          const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          const yyyy = today.getFullYear();
          return yyyy + "-" + mm + "-" + dd;
      };

         //gbconsole.log('rownumber===',rownum);
         //gbconsole.log('statewizard=',this.state.fielddetails[0].WizardNo,this.state.wizardno);
         //gbconsole.log('getfields -- fielddetails=',this.state.fielddetails);
        let formBody = this.state.fielddetails.map((item, i) => {
          if(this.state.fielddetails[i].WizardNo==this.state.wizardno){
            //gbconsole.log('wizardmatched',this.state.fielddetails[i].WizardNo);

         if(this.state.fielddetails[i].RowNum==rownum){
       
          if(this.state.fielddetails[i].IsForeignKey==1 ){
  
  
            if(this.state.fielddetails[i].FieldType=="Dropdown"){
  
              var sr= (
                
              <Col>
                <Form.Group controlId="formBasicEmail" style={this.isdropdowndisplayable(i)?{display:'block'}:{display:'none'}}>
      
        <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
     
      
      <Form.Control as="select"  disabled={this.state.fielddetails[i]['FieldName']=='Course'&&(this.state.admission_sel_courseid!=undefined&&this.state.admission_sel_courseid!=0)?true:this.state.fielddetails[i]['FieldName']=='Session'&&(this.state.admission_sel_sessionid!=undefined&&this.state.admission_sel_sessionid!=0)?true:false} id="selectedrole" className="margin-5 w-60" value={this.state.fielddetails[i]['FieldName']=='Course'&&this.state.admission_sel_courseid!=undefined&&this.state.admission_sel_courseid!=0?this.state.admission_sel_courseid:this.state.fielddetails[i]['FieldName']=='Session'&&this.state.admission_sel_sessionid!=undefined&&this.state.admission_sel_sessionid!=0?this.state.admission_sel_sessionid:this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} name={this.state.fielddetails[i]['FieldName']}>
                      <option key ={0} value={0}>Select {this.state.fielddetails[i]['FieldName']}</option>
                    
                     {
                    (()=>{
                      if(this.state.fielddetails[i]['Dropdownvalues'].length>0){
                      
                        let dropvalues=[];
                          
                        for(var p=0;p<this.state.fielddetails[i]['Dropdownvalues'].length;p++){
                           var sel=true;
                           sel=(this.state.fielddetails[i]['Dropdownvalues'][p]['ID']==25||this.state.fielddetails[i]['Dropdownvalues'][p]['ID']==72)?true:sel;
                           if(this.state.fielddetails[i]['Dropdownvalues'][p]['ID'])
                         

                          dropvalues.push(<option  key={this.state.fielddetails[i]['Dropdownvalues'][p]['ID']} value={this.state.fielddetails[i]['Dropdownvalues'][p]['ID']} selected={true}>{this.state.fielddetails[i]['Dropdownvalues'][p]['Value']}{this.state.fielddetails[i]['Dropdownvalues'][p]['Value2']==undefined?null:((this.state.fielddetails[i]['ID']==292?' ( Rs ':' (')+this.state.fielddetails[i]['Dropdownvalues'][p]['Value2']+')')} </option>);
                                                
                          
                          
      
                      }
                     
                      return dropvalues;
      
                      }
                      else
                      return null;
                        
                          
                     })()
                   }
                   </Form.Control>   
                   <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                  </Form.Text>
      
      
      
      </Form.Group>
      </Col>
     
              );
              return  sr ;
  
            }else if(this.state.fielddetails[i].FieldType=="Multiple dropdown"){
  
              var sr=(
              
                <Col>
                <Form.Group controlId="formBasicEmail">
      
        <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
        <DropdownMultiselect
                    
          options={this.state.fielddetails[i]['Dropdownvalues']}
          name="selectcolumns" selected={this.state.actualvisiblecolumns}
          handleOnChange={(selected) => {
              this.setState({actualvisiblecolumns:selected},()=>{
            //gbconsole.log("i=",i,selected)
            var fieldvalue="";
            var fielddetails=[... this.state.fielddetails];
            for(var s=0;s<selected.length;s++){
             if(s==0)
             fieldvalue=selected[s];
             else
             fieldvalue=fieldvalue+","+selected[s];
            }
        
          for(var q=0;q<fielddetails.length;q++) {
            if(fielddetails[q]['FieldName']==this.state.fielddetails[i].FieldName){
              fielddetails[q]['FieldValue']=fieldvalue;
              break;
            }
           
             
          }
          this.setState({fielddetails: fielddetails},()=>{
             //gbconsole.log(this.state.fielddetails)
          });
  
              })
            }}
        />
         <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
        </Form.Text>
        </Form.Group>
        </Col>
  
              );
              return sr;
            }
  
            
  
          }
          
          else{

            if(this.state.fielddetails[i].FieldType=="Static Dropdown"){
  
              var sr= (
                
              <Col>
                <Form.Group controlId="formBasicEmail">
      
        <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
     
      
      <Form.Control as="select" disabled={this.state.fielddetails[i]['ID']==402&&localStorage.getItem('UserRoleID')==22?true:false}     id="selectedrole" className="margin-5" value={this.state.fielddetails[i]['FieldValue']==''&&this.state.fielddetails[i]['ID']==402&&localStorage.getItem('UserRoleID')==22?'OPEN':this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} name={this.state.fielddetails[i]['FieldName']}>
                      <option key ={0} value={0}>Select </option>
                    
                     {
                    (()=>{
                      if(this.state.fielddetails[i]['Dropdownvalues'].length>0){
                      
                        let dropvalues=[];
                          
                        for(var p=0;p<this.state.fielddetails[i]['Dropdownvalues'].length;p++){
                            if(this.state.fielddetails[i]['Dropdownvalues'][p]['ID'])
                         

                          dropvalues.push(<option  key={this.state.fielddetails[i]['Dropdownvalues'][p]['ID']} value={this.state.fielddetails[i]['Dropdownvalues'][p]['ID']} >{this.state.fielddetails[i]['Dropdownvalues'][p]['Value']} </option>);
                                                
                          
                          
      
                      }
                     
                      return dropvalues;
      
                      }
                      else
                      return null;
                        
                          
                     })()
                   }
                   </Form.Control>   
                   <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                  </Form.Text>
      
      
      
      </Form.Group>
      </Col>
     
              );
              return  sr ;
  
            }

        else if(this.state.fielddetails[i].FieldType=="SubTable"){
          //gbconsole.log('-----subtablefielddetails==',this.state.fielddetails[i]);
          const subtabledetails=this.state.fielddetails[i]['SubTableData'].map((item,index)=>{
            return <tr>
           { 
             item.map((itemn,j)=>{
              return (itemn['FieldType']=='ParentTable')?null:<td>{itemn['FieldType']=='Date'?itemn['FieldTextValue'].split("-")[2]+"/"+itemn['FieldTextValue'].split("-")[1]+"/"+itemn['FieldTextValue'].split("-")[0]:itemn['FieldTextValue']} </td>
            })
          }
          <td onClick={()=>{
            //gbconsole.log(this.state.fielddetails[i]['SubTableData'],this.state.fielddetails[i]['SubTableFieldDetails'],index,i,this.state.fielddetails[i],)
          var field_details=[... this.state.fielddetails]
          for(var se=0;se<field_details[i]['SubTableFieldDetails'].length;se++){
           
              for(var sdf=0;sdf<field_details[i]['SubTableData'][index].length;sdf++){
              if(field_details[i]['SubTableFieldDetails'][se]['ID']==field_details[i]['SubTableData'][index][sdf]['FieldID']){
                field_details[i]['SubTableFieldDetails'][se]['FieldValue']=field_details[i]['SubTableData'][index][sdf]['FieldValue'];
                field_details[i]['SubTableFieldDetails'][se]['EditIndex']=index;
                
              }
            }
            
          }
          this.setState({fielddetails:field_details})
        
        }}>Edit</td>
          <td onClick={()=>{
            // //gbconsole.log(index)
            var field_details=[...this.state.fielddetails];
            var subtable_data=[];
            for(var sdf=0;sdf<field_details[i]['SubTableData'].length;sdf++){
              if(sdf==index)continue;
              else
              subtable_data.push(field_details[i]['SubTableData'][sdf]);

            }
            field_details[i]['SubTableData']=subtable_data;
            this.setState({fielddetails:field_details});

          }}>Delete</td>
            </tr>
          })

          const subtableheader=this.state.fielddetails[i]['SubTableFieldDetails'].map((item,i)=>{

            return ((item.FieldType=='ParentTable')?null:<th>{item.FieldLabel==null?item.FieldName:item.FieldLabel}</th>)})
          var sr= (
               
            <Col>
                <table className="subtable">
                  <tr>
                  {subtableheader}
                  </tr>
                  <tr>
                  {this.getSubFields(i)}
                  </tr>
                </table>
                <Button variant="secondary" style={{marginBottom:'5px'}}onClick={()=>{this.addSubTableData(i)}}>Add</Button>
                <table className="subtabledetails">
                  <tr>
                  {subtableheader}
                  <th></th>
                  <th></th>
                  </tr>
                  {subtabledetails}

                </table>

            </Col>
              );
              return sr;

        }
           else if(this.state.fielddetails[i].FieldType=="Date"){
              //gbconsole.log('type===date');
  
              var sr= (
               
            <Col>
                <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control type="date" min={this.state.fielddetails[i]['ID']==122?disablePastDate():null}  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />  
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text> 
                </Form.Group>
            </Col>
              );
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="DateTime"){
              //gbconsole.log('type===datetime');
  
              var sr= (
               
            <Col>
                <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control type="datetime-local"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />  
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text> 
                </Form.Group>
            </Col>
              );
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="Email"){
  
              var sr= (
               
            <Col>
                <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control type="email"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} /> 
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text>    
                </Form.Group>
            </Col>
              );
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="Text Area"){
  
              var sr= (
                
            <Col>
                <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control as="textarea"   name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text> 
                </Form.Group>
            </Col>
              );
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="Url"){
  
              var sr= (
                
            <Col>
                <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control type="url"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} /> 
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text>     
                </Form.Group>
            </Col>);
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="Check Box"){
  
              var sr= (
                
            <Col>
                {/* <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control type="url"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />     
                </Form.Group> */}

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" name={this.state.fielddetails[i]['FieldName']} checked={this.state.fielddetails[i].FieldValue} label={this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}  onChange={this.handleChange}/>
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text> 
               </Form.Group>
            </Col>);
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="Telephone"){
  
              var sr= (
               
            <Col>
                <Form.Group controlId="formBasicEmail">      
                <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
                <Form.Control type="tel"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
                <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                </Form.Text> 
                </Form.Group>
            </Col>
              );
              return sr;
            }
            else if(this.state.fielddetails[i].FieldType=="Image"){
  
              var sr= (
                
            <Col>
                <Form.Group controlId="formBasicEmail">
      
        <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
     
     <p>
      <Image style={{height:'50px',width:'50px',marginRight:'10px'}} src={this.state.fielddetails[i]['FieldValue']} rounded />
      <FileBase64
          multiple={ true }
          onDone={ 
           
           (file)=>{ 
            var field_details=[... this.state.fielddetails];
            field_details[i]['FieldValue']=file[0]['base64'];
            this.setState({fielddetails:field_details});
            //gbconsole.log(i)
           }
          } />
     </p>
  
     
      </Form.Group>
      </Col>
              );
              return sr;
            }
            else{
  if(this.state.fielddetails[i].FieldType!="Datagrid"){
              var sr= (
            
            <Col style={ (this.isdisplayable(this.state.fielddetails[i].FieldName))?null:{display:'none'}}>
                <Form.Group controlId="formBasicEmail">
      
        <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
      <Form.Control type="text" disabled={this.state.fielddetails[i]['ID']==298||this.state.fielddetails[i]['ID']==322||this.state.fielddetails[i]['ID']==389||this.state.fielddetails[i]['ID']==401||this.state.fielddetails[i]['ID']==388?true:false}  name={this.state.fielddetails[i]['FieldName']} style={this.state.fielddetails[i]['FieldName']=='Enquiry Ref No'?{width:'50%'}:null} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
      {this.state.fielddetails[i]['FieldName']=='Enquiry Ref No'?<Button variant="dark" style={{marginTop:'5px'}}
      onClick={()=>{
      var fielddetails=[...this.state.fielddetails]
      var dropdowns=JSON.parse(localStorage.getItem("DropDownRows") || "[]");
      var firstname='';
      var lastname='';
      var middlename='';
      var contactno='';
      var dob='';
      var enqrefno='';
      for(var en=0;en<fielddetails.length;en++){
        if(fielddetails[en]['FieldName']=='Enquiry Ref No'){
          enqrefno=fielddetails[en]['FieldValue'];
          break;
        }
      }
      if(enqrefno==''){
        alert('Please enter Enquiry Ref No');
      }else{

        var flag=false;
        for(var ed=0;ed<dropdowns.length;ed++){
          if(dropdowns[ed]['DropdownID']==34&&dropdowns[ed]['Enq ref']==enqrefno){
            firstname=dropdowns[ed]['First Name']
            lastname=dropdowns[ed]['Last Name']
            middlename=dropdowns[ed]['Middle Name']
            contactno=dropdowns[ed]['contactno']
            dob=dropdowns[ed]['Dob']
            flag=true;
            break;
            
  
          }
  
        }
        if(flag==false){
          alert('Enquiry Ref No doesnot exist')
          for(var en=0;en<fielddetails.length;en++){
            if(fielddetails[en]['FieldName']=='Enquiry Ref No'){
             fielddetails[en]['FieldValue']='';
            }
          }
          this.setState({fielddetails:fielddetails},()=>{
            //gbconsole.log('enqdetails=',this.state.fielddetails)
          },()=>{
            
          })

        }else{

          for(var en=0;en<fielddetails.length;en++){
            if(fielddetails[en]['FieldName']=='First Name'){
             fielddetails[en]['FieldValue']=firstname;
            }
            if(fielddetails[en]['FieldName']=='Middle Name'){
             fielddetails[en]['FieldValue']=middlename;
            }
            if(fielddetails[en]['FieldName']=='Last Name'){
             fielddetails[en]['FieldValue']=lastname;
            }
            if(fielddetails[en]['FieldName']=='DOB'){
             fielddetails[en]['FieldValue']=dob;
            }
            if(fielddetails[en]['FieldName']=='Mobile No'){
             fielddetails[en]['FieldValue']=contactno;
            }
         }
         this.setState({fielddetails:fielddetails},()=>{
           //gbconsole.log('enqdetails=',this.state.fielddetails)
         })

        }
       

      }
     

      }}
      >Fetch enquiry details</Button>:null} 
      <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
      </Form.Text> 
      
      </Form.Group>
      </Col>
      
              );
              return sr;
  }
  
            }
  
  
  
           
          }

        }
      }
        });
  

return formBody;
      }

      setselectedarray=(selectionarray)=>{
   
        this.setState({selectedidarray:selectionarray},()=>{
        //gbconsole.log('selectedidarray=',this.state.selectedidarray)
        
      });
      
      }
      

    render() {
      console.log('userroleid=',localStorage.getItem('UserRoleID'));
      console.log('firstname=',localStorage.getItem('FirstName'));

      const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };




      if(this.state.ready==false)
      return(<div className='waitdiv'><center><img className="waitimage" src={waitimage} /></center></div>)
      let rownum=0;
      let formrow=this.state.fielddetails.map((item, i) => {
      if(rownum!=this.state.fielddetails[i].RowNum){
        rownum=this.state.fielddetails[i].RowNum;
        return (<Row>{this.getfields(rownum)}</Row>);
      }

      })

      let wizard_buttons=this.state.wizardbuttons.map((item, i) => {

        return( <Button className={this.state.wizardbuttons[i]==this.state.wizardbutton?'activemodaltab':'modaltab'}   variant="outline-success" onClick={()=>{this.setState({wizardno:this.state.wizardnos[i],wizardtitle:this.state.wizardtitles[i],wizardbutton:this.state.wizardbuttons[i]},()=>{
          //gbconsole.log('state after wizard button click=',this.state)
        })}}>{this.state.wizardbuttons[i]}</Button>);
      });

      let formBody = this.state.fielddetails.map((item, i) => {

        const rowendtag =(i==this.state.fielddetails.length-1||this.state.fielddetails[i+1].FieldOrder==1)?"</Row>":null;

      const rowstarttag=(this.state.fielddetails[i].FieldOrder==1)?"<div class='row'>":null;
     
        if(this.state.fielddetails[i].IsForeignKey==1 ){


          if(this.state.fielddetails[i].FieldType=="Dropdown"){

            var sr= (
             <Row> 
            <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
   
    
    <Form.Control as="select" id="selectedrole" className="margin-5 w-60" value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} name={this.state.fielddetails[i]['FieldName']}>
                    <option key ={0} value={0}>Select1 {this.state.fielddetails[i]['FieldName']}</option>
                  
                   {
                  (()=>{
                    if(this.state.fielddetails[i]['Dropdownvalues'].length>0){
                    
                      let dropvalues=[];
                        
                      for(var p=0;p<this.state.fielddetails[i]['Dropdownvalues'].length;p++){
                        var sel=true;
                           sel=(this.state.fielddetails[i]['Dropdownvalues'][p]['ID']==25||this.state.fielddetails[i]['Dropdownvalues'][p]['ID']==72)?true:sel;
                       
                        dropvalues.push(<option selected={true} key={this.state.fielddetails[i]['Dropdownvalues'][p]['ID']} value={this.state.fielddetails[i]['Dropdownvalues'][p]['ID']}>{this.state.fielddetails[i]['Dropdownvalues'][p]['Value']} </option>);
                                              
                        
                        
    
                    }
                   
                    return dropvalues;
    
                    }
                    else
                    return null;
                      
                        
                   })()
                 }
                 </Form.Control>  
                 <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
                  </Form.Text> 
    
    
    
    </Form.Group>
    </Col>
    </Row>
   
            );
            return  sr ;

          }else if(this.state.fielddetails[i].FieldType=="Multiple dropdown"){

            var sr=(
              <Row>
              <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
      <DropdownMultiselect
                  
        options={this.state.fielddetails[i]['Dropdownvalues']}
        name="selectcolumns" selected={this.state.actualvisiblecolumns}
        handleOnChange={(selected) => {
            this.setState({actualvisiblecolumns:selected},()=>{
          //gbconsole.log("i=",i,selected)
          var fieldvalue="";
          var fielddetails=[... this.state.fielddetails];
          for(var s=0;s<selected.length;s++){
           if(s==0)
           fieldvalue=selected[s];
           else
           fieldvalue=fieldvalue+","+selected[s];
          }
      
        for(var q=0;q<fielddetails.length;q++) {
          if(fielddetails[q]['FieldName']==this.state.fielddetails[i].FieldName){
            fielddetails[q]['FieldValue']=fieldvalue;
            break;
          }
         
           
        }
        this.setState({fielddetails: fielddetails},()=>{ 
          //gbconsole.log(this.state.fielddetails)
        });

            })
          }}
      />
       <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
        </Form.Text>
      </Form.Group>
      </Col>
      </Row>

            );
            return sr;
          }

          

        }
        
        else{
          
          if(this.state.fielddetails[i].FieldType=="Date"){

            var sr= (
             <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
    <Form.Control type="date" min={this.state.fielddetails[i]['ID']==122?disablePastDate():null} name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
    <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
    </Form.Text>
   
    </Form.Group>
    </Col>
    </Row>
            );
            return sr;
          }
          if(this.state.fielddetails[i].FieldType=="DateTime"){

            var sr= (
             <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
    <Form.Control type="datetime-local"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
    <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
    </Form.Text>
   
    </Form.Group>
    </Col>
    </Row>
            );
            return sr;
          }
          else if(this.state.fielddetails[i].FieldType=="Email"){

            var sr= (
             <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
    <Form.Control type="email"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
    <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
    </Form.Text>
   
    </Form.Group>
    </Col>
    </Row>
            );
            return sr;
          }
          else if(this.state.fielddetails[i].FieldType=="Text Area"){

            var sr= (
              <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
    <Form.Control as="textarea"   name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
    <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
    </Form.Text>
   
    </Form.Group>
    </Col>
    </Row>
            );
            return sr;
          }
          else if(this.state.fielddetails[i].FieldType=="Url"){

            var sr= (
              <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
    <Form.Control type="url"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
    <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
    </Form.Text>
   
    </Form.Group>
    </Col>
    </Row>);
            return sr;
          }
          else if(this.state.fielddetails[i].FieldType=="Telephone"){

            var sr= (
             <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">    
              <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
              <Form.Control type="tel"  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
              <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
              </Form.Text>
              </Form.Group>
          </Col>
          </Row>
            );
            return sr;
          }
          else if(this.state.fielddetails[i].FieldType=="Image"){

            var sr= (
             <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">    
              <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
           <p>
            <Image style={{height:'50px',width:'50px',marginRight:'10px'}} src={this.state.fielddetails[i]['FieldValue']} rounded />
            <FileBase64
              multiple={ true }
              onDone={ 
        
             (file)=>{ 
                      var field_details=[... this.state.fielddetails];
                      field_details[i]['FieldValue']=file[0]['base64'];
                      this.setState({fielddetails:field_details});
                       //gbconsole.log(i)
                     }
                    } />

           </p>
  
    </Form.Group>
    </Col>
    </Row> 
            );
            return sr;
          }
          else{

            if(this.state.fielddetails[i].FieldType!="Datagrid"){
            var sr= (
          <Row>
          <Col>
              <Form.Group controlId="formBasicEmail">
    
      <Form.Label>{this.state.fielddetails[i].FieldLabel==''?this.state.fielddetails[i].FieldName:this.state.fielddetails[i].FieldLabel}</Form.Label>
    <Form.Control type="text" disabled={this.state.fielddetails[i]['ID']==298?true:false}  name={this.state.fielddetails[i]['FieldName']} value={this.state.fielddetails[i]['FieldValue']} onChange={this.handleChange} />
    <Form.Text id="passwordHelpBlock" muted>
                   {this.state.fielddetails[i]['FieldErrorText']}
    </Form.Text>
    
    </Form.Group>
    </Col>
    </Row>
    
            );
            return sr;

          }
        }


         
        }
       
      });



        if(this.state.ready==false)
        return(<div></div>)
        else
 

    return (
      
        <Container style={this.state.token==null&&this.state.enqtoken==null?{}:{backgroundColor:'white'}}>
          {
        this.props.tablename=='Student Application'?
        <Container>
        <Row style={{marginBottom:'10px'}}>
          <Col>
          
          {this.getdropdown("Application Status","Application status")}
          </Col>
          <Col>
          <Form.Control type="datetime-local"  name="applicationfollowupdate" value={this.state.applicationfollowupdate} onChange={this.handleChange}  />

          </Col>
          </Row>
          <Row style={{marginBottom:'10px'}}>
          
          <Col>
          <Form.Control as="textarea"   name="applicationfollowupcomment" value={this.state.applicationfollowupcomment} onChange={this.handleChange} placeholder="Comments" />
          </Col>
          </Row>
          <Row>

          <Col>
          <Button variant="secondary" disabled={this.state.studentapplicationbutton=='Apply'?false:true} style={{marginBottom:'5px'}}onClick={()=>{
             this.handleStudentApplication()
            }}>{this.state.studentapplicationbutton}</Button>
          </Col>
        </Row>
        </Container>
        
        :null
      }
      <Row>
       
          <Col>
          

<Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>{this.props.tablename} created/updated</Toast.Body>
</Toast>



          </Col>
     
     </Row>
     {
    //  localStorage.getItem('UserRoleID')==18?null:
    this.state.token==null&&this.state.enqtoken==null?
     <AddRole>
     <Button variant="primary" type="button" onClick={()=>{
       //gbconsole.log('iswizard=',this.state.fielddetails[0].IsWizard,this.state.fielddetails);
     //gbconsole.log('fielddetails-----12=>',this.state.fielddetails);
      var field_details=[...this.state.fielddetails];
      for(var i=0;i<field_details.length;i++){
       field_details[i]['SubTableData']=[];
      }
     this.setState({showmodal:true,recordid:0,fieldvalue:'',fielddetails:field_details})}}>Add</Button>
     </AddRole>:null
     
     }
      
     
    
    {
     (this.props.showlist=='no')?<div></div>:
     <Row>
     <Col>{
       this.props.enquiryid!=undefined?
       <FormHeader>Followup Details for  '{this.props.enquiryreference}'</FormHeader>:(this.props.qdnid!=undefined?
        <FormHeader>QDN Details for  '{this.props.qdnno}'</FormHeader>:
       <FormHeader>Existing {this.props.tablecaption==null?this.props.tablename:this.props.tablecaption}</FormHeader>)
     }
     
     <DataGrid  tableid={this.state.tableid} rowsarray={this.state.tablelists} advancefilter={this.state.tableadvancefilter} columns={this.props.columnstoview==undefined?this.state.columnstoview:this.props.columnstoview}   seteditid={this.seteditid } showdiscdetail={this.props.showdiscdetail=='yes'?'yes':'no'} showdatagriddropdown1={this.props.showdatagriddropdown1=='yes'?'yes':'no'} showdatagridevaluation={this.props.showdatagridevaluation=='yes'?'yes':'no'} councellordropdown={this.props.tablename=='Enquiry Master'?'yes':'no'} followupcallback={this.followupcallback} showselection={this.props.tablename=='Student Application'?true:false} setselectedarray={this.setselectedarray}  />
     </Col>
     </Row>
    }
     
    


   {
   //gbconsole.log('modal---fielddetails--',this.state.fielddetails[0])
  }
    <Modal  size={this.state.fielddetails[0].IsWizard==1||(this.state.fielddetails.length>1&&this.state.fielddetails[1].IsWizard==1)?'xl':''}  show={this.state.showmodal} onHide={this.state.token==null&&this.state.enqtoken==null?this.hideModal:null} aria-labelledby="contained-modal-title-vcenter">
    
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update {this.props.tablecaption==null?this.props.tablename:this.props.tablecaption}
           
          </Modal.Title>
         
        </Modal.Header>
        <p className='modalsubtitle'>{this.state.fielddetails[0].IsWizard==1?this.state.wizardtitle:''}</p>
        <Modal.Body className="show-grid">
          <Container>
          <Row>
          
          <Col>
          
          <Form onSubmit={this.submitData}>





{(this.state.fielddetails[0].RowNum !=0||this.state.fielddetails[1].RowNum !=0)?formrow:formBody}

{
this.props.tablename=='AdmissionTYI'&&this.state.wizardno==this.state.wizardbuttons.length?

<Row className='bottomconfirm'>
<Col>
<h4>DECLARATION BY STUDENT/PARTICIPANT</h4>
<p>
I, HEREBY CONFIRM THAT I HAVE READ AND AGREE TO THE ABOVE TERMS AND CONDITIONS. <br/>

I ALSO CONFIRM THAT <br/>

<b>1.</b> I am participating in the programme of The Yoga Institute and I hereby take full and sole responsibility from any liability of loss <br/>
or damage to personal property associated with the programme or any other events <br/>
<b>2.</b> IfI am pregnant, I understand that I participate fully at my own risk and that of my unborn child/children. <br/>
<b>3.</b> In submitting the registration form I have read the above release and waiver of liability and fully understand its contents. <br/>
I voluntarily agree to the terms and conditions stated above under my own free wil.<br/>
</p>
</Col>
</Row>
:
<Row>
  <Col></Col>
</Row>
}         

{this.state.fielddetails[0].IsWizard==1?

 this.state.wizardno==this.state.wizardbuttons.length?           

<Row>

<Col>
<Button variant="primary" type="submit" disabled={this.state.submitbutton=='Submit'?false:true} >
{this.state.submitbutton}
</Button>

{this.props.tablename=='Gencode'?
<Button variant="secondary" style={{margin:'5px'}} size="md" onClick={this.handleGencode}>
      Test
</Button>
:null}
</Col>
</Row>
:null


:
<Row>
<Col>
<Button variant="primary" type="submit" disabled={this.state.submitbutton=='Submit'?false:true}>
{this.state.submitbutton}
</Button>

{this.props.tablename=='Gencode'?
<Button variant="secondary" style={{margin:'5px'}} size="md" onClick={this.handleGencode}>
      Test
</Button>
:null}
</Col>
</Row>

    }
</Form>



<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-failure">Failure</strong>                    
</Toast.Header>
<Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
</Toast>

          </Col>
     
     </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="wizardbuttongroup">
          {wizard_buttons}
          </div>
          {
            this.state.token==null&&this.state.enqtoken==null?
          <Button onClick={this.hideModal}>Close</Button>:null
    }
        </Modal.Footer>
        
      </Modal>








</Container>
    )
    }
}

export default DropDownMaster
