import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Toast} from 'react-bootstrap'
import styled from 'styled-components';

const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    background-color: white;
    font-weight: bold;
    text-align: center;`;
	
	const ButtonLink=styled.button`
    margin-left: 8px;
    
    background-color:lightblue;
    font-weight: bold;
    text-align: center;`;

export class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid:localStorage.getItem('UserID'),
            username: localStorage.getItem('UserName'),
            currentpassword:'',
            password: '',
            confirmpassword: '',
            showtoast1:false,
            showtoast2:false,
            
            ready: false,
          }
        }

        componentDidMount() { 

          var username=localStorage.getItem('UserName');
          this.setState({username:username},()=>{this.setState({ready:true})});
		      //gbconsole.log('recover pasword called');
         
        }
        toggleShowA=(event)=>{
          this.setState({showtoast1:false});
        }
        toggleShowB=(event)=>{
          this.setState({showtoast2:false});
        }
        toggleShowC=(event)=>{
            this.setState({showtoast3:false});
          }
        backtologin=(event)=>{
          event.preventDefault();
          this.props.refreshCallback('login');
        }
        
        submitPassword=(event)=>{
            event.preventDefault();
            //gbconsole.log()
            if(this.state.currentpassword==''){
                alert('Please provide current password');
                return;
            }
            if(this.state.password==this.state.confirmpassword){


              let postData = {
                "CurrentPassword": this.state.currentpassword,
                "NewPassword": this.state.password
                  
          
          }

          
          let url = 'http://localhost/scm/api/token' ;    
          url = process.env.REACT_APP_DEBUG_URL+'/api/ChangePassword';
          let usertoken =localStorage.getItem("UserToken");
        //  let url = 'https://wocdata.com/api/token' ;  
          //postData = { "command": "addcompany"};
          
          let httpVerb = { method: "POST",   body:postData };
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+usertoken },
              body: JSON.stringify(postData)
             // body: postData
          };
          
          fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              
    if(result.Status=='SUCCESS'){
      this.setState({showtoast1:true,password:'',currentpassword:'',confirmpassword:''});
      
              
        }else
        {
            alert(result.Message);
           
        }   
    
            });  





            }
            else{

              this.setState({showtoast2:true});
            }
          }
          handleChange = (event) => {    
            this.setState({[event.target.name]: event.target.value});    
          }

    render() {

      if(this.state.ready==false)
      return <div></div>
      else
        return (
          <Container>
          <Row>
              <Col>
              <FormHeader>Change Password</FormHeader>
              <Form onSubmit={this.submitPassword}>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>UserID</Form.Label>
    <Form.Control type="text"  name="username" value={this.state.username} onChange={this.handleChange}  readOnly />
    {/* <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text> */}
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Current Password</Form.Label>
    <Form.Control type="password"  name="currentpassword" value={this.state.currentpassword} onChange={this.handleChange} />
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>New Password</Form.Label>
    <Form.Control type="password"  name="password" value={this.state.password} onChange={this.handleChange} />
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Confirm Password</Form.Label>
    <Form.Control type="password"  name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} />
  </Form.Group>
  
  <Button variant="primary" type="submit">
    Submit
  </Button>
   
  
</Form>
<Toast show={this.state.showtoast1} onClose={this.toggleShowA}>
                     <Toast.Header>            
                     <strong className="mr-auto">Success</strong>                    
                     </Toast.Header>
                    <Toast.Body>Pasword reset successfully</Toast.Body>
                     </Toast>
<Toast show={this.state.showtoast2} onClose={this.toggleShowB}>
                     <Toast.Header>            
                     <strong className="mr-auto">Failure</strong>                    
                     </Toast.Header>
                    <Toast.Body>Password and Confirm password doesnot match!</Toast.Body>
                     </Toast>
              </Col>
         
         </Row>
         
</Container>
        )
    }
}

export default ChangePassword;
