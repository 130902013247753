import React from 'react';
import '../style.css';
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import leftimage from '../assets/images/weblogin1.jpg';
import turtlelogo from '../assets/images/turtlelogo.png';


class Login extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            intake_value: null,
            financialyrID:0,
            ready: false,
            submittext:'Submit'
          }
    }
    
    componentDidMount() { 

        // const requestOptions1 = {
        //     method: 'GET',
        //     headers: { 'Content-Type': 'application/json' ,'Authorization': 'Bearer '+token},
            
        // };

        // fetch('http://localhost/scm/api/GetCompanyBranchList', requestOptions1)
        // .then(response => response.json())
        // .then(data => 
        ////gbconsole.log('branches=',data)
        //);

        
    
        

        
       
      }
      handleChange = (event) => {
    
        this.setState({[event.target.name]: event.target.value});         
    }   
    forgotpassword = (event) => {  
        event.preventDefault();      
        this.props.refreshCallback('forgotpassword');
    
    }

      submitLoginValue= (event) => {
          //gbconsole.log('login called');
          this.setState({submittext:'Loading...'})

        let token="";
        let postData = {
            "grant_type": "password",
            "username": this.state.username,
            "password": this.state.password,
            "CompanyID": "1",      
            "DeviceID":"abcde-ea12-3456-2jhk56-454",
            "TimeStamp":"9/6/2021, 20:15:48"
            //"BranchKey": (this.BranchKey==""||this.BranchKey==null)?"abcd":this.BranchKey
      
      }
        
      let url = 'http://localhost/scm/api/token' ;    
      url = process.env.REACT_APP_DEBUG_URL+'/api/token';
    //  let url = 'https://wocdata.com/api/token' ;  
      //postData = { "command": "addcompany"};
      
      let httpVerb = { method: "POST",   body:postData };
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postData)
         // body: postData
      };
      fetch(url, requestOptions)
        .then(res => res.json())
        .then(result => {
            this.setState({submittext:'Submit'})
          //gbconsole.log(result)
          //gbconsole.log("branch=",result.branchid)
          token=result.access_token;
if(token==undefined){
    alert("Username or Password does not match our records. Please try again.");
    this.props.refreshCallback('login');
          
    }else
    {
        try{
        localStorage.setItem('UserToken',token);
        localStorage.setItem('LoginID',result.loginid);
        localStorage.setItem('UserID',result.userid);
        localStorage.setItem('UserName',result.username);
        localStorage.setItem('UserRole',result.role);
        localStorage.setItem('FirstName',result.firstname);
        localStorage.setItem('Users',JSON.stringify(result.users));
        localStorage.setItem('UserRoleID',result.roleid);
        localStorage.setItem("UserRoles", JSON.stringify(result.roles));
        localStorage.setItem("MenuRoles", JSON.stringify(result.menuroles));
        localStorage.setItem("UserRolesAssigned", JSON.stringify(result.rolesassigned));
        localStorage.setItem("Menus", JSON.stringify(result.menus));

        localStorage.setItem("DropDownFields", JSON.stringify(result.dropdownfields));
        localStorage.setItem("DropDownMaster", JSON.stringify(result.dropdownmaster));
        
        localStorage.setItem("StaffCalendar", JSON.stringify(result.staffcalendar));
        localStorage.setItem("StudentCalendar", JSON.stringify(result.studentcalendar));
        
       // localStorage.setItem("DropDownRows", JSON.stringify(result.dropdownrows));
      //  localStorage.setItem("DropDowns", JSON.stringify(result.dropdowns));
        
        }catch(e){
            console.log('local storage is full',localStorage);
        }
        this.props.refreshCallback('home');
    }   

        });  
         
  
         
        event.preventDefault();
    }


      render() { 

        return (
      
            <div className="auth-wrapper">
                {/* <div className="auth-inner-2">
                


                </div> */}
                

                
        <div className="auth-inner">
            
            <form onSubmit={this.submitLoginValue} style={{marginTop:'100px'}}>
           
                <h3>Sign In</h3>
                <div className="form-group" style={{backgroundColor:'black',padding:'20px'}}>
                <img src={turtlelogo} width='200px' />
                </div>

                <div className="form-group">
                    <label>Username</label>
                    <input type="text" className="form-control" name="username" value={this.state.username} onChange={this.handleChange} placeholder="Username" />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} className="form-control"  />
                </div>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                    </div>
                </div>

                {/* <button type="submit" className="btn btn-primary btn-block"  >{this.state.submittext}</button> */}

                <Button variant="primary" type="submit" className="btn btn-primary btn-block" disabled={this.state.submittext=='Submit'?false:true}>
                {this.state.submittext}
                </Button>
                <p className="forgot-password text-right">
                    Forgot <a onClick={this.forgotpassword}>password?</a>
                </p>
            </form>
            </div>
            
            </div>
        );
      }




}

export default Login;
 